import React, { useEffect } from 'react';

const CloseWindow = () => {
  useEffect(() => {
    window.close();
  }, []);

  return (
    <div>Billdesk</div>
  );
};

export default CloseWindow;
