import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as AwardsIcon } from "../../assets/images/menu/award_white.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/menu/dashboard.svg";
import { ReactComponent as RegistrationsIcon } from "../../assets/images/menu/registration.svg";
import { ReactComponent as AdmissionsIcon } from "../../assets/images/menu/admissions.svg";
import { ReactComponent as AttendanceIcon } from "../../assets/images/menu/attendance.svg";
import { ReactComponent as CoursesIcon } from "../../assets/images/menu/courses.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/images/menu/documents.svg";
import { ReactComponent as MaterialsIcon } from "../../assets/images/menu/materials.svg";
import { ReactComponent as NotificationsIcon } from "../../assets/images/menu/notification_white.svg";
import { ReactComponent as ProfessorsIcon } from "../../assets/images/menu/professors.svg";
import { ReactComponent as SubjectsIcon } from "../../assets/images/menu/subjects.svg";
import { ReactComponent as SyllabsIcon } from "../../assets/images/menu/syllabus.svg";
import { ReactComponent as StudentsIcon } from "../../assets/images/menu/students.svg";
import { ReactComponent as FaqsIcon } from "../../assets/images/menu/faqs.svg";
import { ReactComponent as FeeIcon } from "../../assets/images/menu/fee.svg";
import { ReactComponent as FlowIcon } from "../../assets/images/menu/flow.svg";
import { ReactComponent as LogsIcon } from "../../assets/images/menu/logs.svg";
import { ReactComponent as MarksIcon } from "../../assets/images/menu/marks.svg";
import { ReactComponent as NoticeBoardIcon } from "../../assets/images/menu/noticeboard.svg";
import { ReactComponent as PaymentsIcon } from "../../assets/images/menu/payments.svg";
import { ReactComponent as RolesIcon } from "../../assets/images/menu/roles.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/menu/settings.svg";
import { ReactComponent as Student360Icon } from "../../assets/images/menu/student_360.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/menu/users.svg";
import { ReactComponent as WorkshopsIcon } from "../../assets/images/menu/workshops.svg";
import { ReactComponent as TransitionIcon } from "../../assets/images/menu/moneyI_transaction_white.svg";
import { ReactComponent as LibraryIcon } from "../../assets/images/menu/books_white.svg";
import { ReactComponent as BooksIcon } from "../../assets/images/menu/books_white.svg";
import { ReactComponent as JournalsIcon } from "../../assets/images/menu/Journals_white.svg";
import { ReactComponent as IssuesIcon } from "../../assets/images/menu/issues_white.svg";
import { ReactComponent as LibrarianDashboardIcon } from "../../assets/images/menu/dashboard.svg";
import { ReactComponent as ActiveUsersIcon } from "../../assets/images/menu/active_users_white.svg";

const MenuIconMapping = {
  Dashboard: <DashboardIcon />,
  "Library Dashboard": <LibrarianDashboardIcon />,
  Registrations: <RegistrationsIcon />,
  Admissions: <AdmissionsIcon />,
  Slide: <AdmissionsIcon />,
  Students: <StudentsIcon />,
  Professors: <ProfessorsIcon />,
  Courses: <CoursesIcon />,
  Subjects: <SubjectsIcon />,
  Documents: <DocumentsIcon />,
  Noticeboard: <NoticeBoardIcon />,
  Notifications: <NotificationsIcon />,
  Attendance: <AttendanceIcon />,
  Materials: <MaterialsIcon />,
  Syllabus: <SyllabsIcon />,
  Catalogue: <BooksIcon />,
  Circulations: <IssuesIcon />,
  "Issued Books": <IssuesIcon />,
  "Returned Books": <JournalsIcon />,
  "Damaged Books": <IssuesIcon />,
  "Issued Journals": <IssuesIcon />,
  "Returned Journals": <JournalsIcon />,
  "Damaged Journals": <IssuesIcon />,
  Journals: <JournalsIcon />,
  'Requested Books': <BooksIcon />,
  'Requested Journals': <BooksIcon />,
  "Assign Books": <BooksIcon />,
  "Assign Journal": <BooksIcon />,
  "Assign Transfer Books": <BooksIcon />,
  "Request Transfer Books": <BooksIcon />,
  "Recommend Books": <BooksIcon />,
  "Approval Flow": <FlowIcon />,
  "Roles & Permissions": <RolesIcon />,
  Users: <UsersIcon />,
  Awards: <AwardsIcon />,
  Fee: <FeeIcon />,
  'Fee Type': <FeeIcon />,
  'Hostel Fee': <FeeIcon />,
  Workshops: <WorkshopsIcon />,
  "Student 360": <Student360Icon />,
  ActiveUsers: <ActiveUsersIcon />,
  Marks: <MarksIcon />,
  Logs: <LogsIcon />,
  "Accounts": <PaymentsIcon />,
  Transactions: <TransitionIcon />,
  Refund: <PaymentsIcon />,
  "Ledger": <PaymentsIcon />,
  "MQPayments": <PaymentsIcon />,
  Payments: <PaymentsIcon />,
  "Payments Summary": <PaymentsIcon />,
  "Head Ledger": <PaymentsIcon />,
  Downloads: <DocumentsIcon />,
  Settings: <SettingsIcon />,
  FAQs: <FaqsIcon />,
  Library: <LibraryIcon />,
};

function DefaultLink(props) {

  const [showSubMenu, setShowSubMenu] = useState(false);
  const onClick = (e) => {
    if (props.itemProps.hasSubMenu) {
      props.itemProps.toggleSubMenu(e);
      setShowSubMenu(!showSubMenu);
    } else {
      props.itemProps.activateMe({
        newLocation: props.to,
        selectedMenuLabel: props.label,
      });

    }
  };

  const { itemProps } = props;
  const label = itemProps.label;
  const MenuIcon = MenuIconMapping[label] || <UsersIcon />;
  // const currentRoute = useLocation();
  // console.log(itemProps);

  return (
    <NavLink
      to={`${itemProps.to}`}
      activeClassName=""
      className={`menuitem ${itemProps.active || itemProps.hasActiveChild ? "active" : ""}`}
      style={{ padding: ''}}
      onClick={onClick}
    >
      <span className="menuitem-icon">{MenuIcon}</span>
      <span className="font-16 pl-15">{itemProps.label}</span>
      {itemProps?.hasSubMenu ?
        <i className={`fa ${showSubMenu ? "fa-chevron-down" : "fa-chevron-up"}`}
          style={{ position: "absolute", top: "30%", right: "0px" }}
        ></i>
        : null
      }
    </NavLink>
  );
}

const mapStateToProps = (state) => ({
  subMenuIcon: state.settings.isSubMenuIcon,
  menuIcon: state.settings.isMenuIcon,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLink);
