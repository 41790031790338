import React, { Component } from 'react';
import AuthService from '../../services/AuthService';

class SessionTimeout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 90 * 60 * 1000, // 90 minutes in milliseconds
      isTimedOut: false,
    };

    // Initialize an inactivity timer
    this.timer = null;

    // Bind event listeners to this component instance
    this.resetSessionTimeout = this.resetSessionTimeout.bind(this);
    this.logout = this.logout.bind(this);
  }

  // Function to reset the session timeout
  resetSessionTimeout() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(this.logout, this.state.timeout);
  }

  // Function to handle logout or session expiration
  logout() {
    // Add your logout logic here, such as redirecting to a logout page
    this.setState({ isTimedOut: true });
    AuthService.logout();
    // Redirect to the logout page
    // Replace '/logout' with the actual URL of your logout page
    window.location.href = '/#/login';
  }

  componentDidMount() {
    // Start the session timeout timer when the component mounts
    this.resetSessionTimeout();

    // Add event listeners to track user activity
    window.addEventListener('mousemove', this.resetSessionTimeout);
    window.addEventListener('keydown', this.resetSessionTimeout);
    window.addEventListener('click', this.resetSessionTimeout);
  }

  componentWillUnmount() {
    // Clean up event listeners and the timer when the component unmounts
    if (this.timer) {
      clearTimeout(this.timer);
    }
      
    window.removeEventListener('mousemove', this.resetSessionTimeout);
    window.removeEventListener('keydown', this.resetSessionTimeout);
    window.removeEventListener('click', this.resetSessionTimeout);
  }

  render() {
    return (
      <>
        {this.state.isTimedOut && (
          <p style={{ background: 'red', color: 'white', marginTop: '10px' }}>Session expired. You have been logged out.</p>
        )}
      </>
    );
  }
}

export default SessionTimeout;
