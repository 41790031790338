export const setCourseData = (e) => dispatch => {
	dispatch({
		type: 'COURSE',
		payload: e
	})
}
export const setNoticeData = (e) => dispatch => {
	dispatch({
		type: 'NOTICEBOARD',
		payload: e
	})
}

export const setSubCourseData = (e) => dispatch => {
	dispatch({
		type: 'SUBCOURSE',
		payload: e,
	})
}

export const setYearsData = (e) => dispatch => {
	dispatch({
		type: 'YEARS',
		payload: e,
	})
}

export const setCourseDataById = (e) => dispatch => {
	dispatch({
		type: 'COURSEID',
		payload: e
	})
}

export const setSubCourseDataById = (e) => dispatch => {
	dispatch({
		type: 'SUBCOURSEID',
		payload: e,
	})
}

export const setPermissions = (e) => dispatch => {
	dispatch({
		type: 'PERMISSION',
		payload: e
	})
}

export const setNotificationCount = (e) => dispatch => {
	dispatch({
		type: 'NOTIFICATIONCOUNT',
		payload: e
	})
}

export const setCategory = (e) => dispatch => {
	dispatch({
		type: 'CATEGORY',
		payload: e,
	})
}

export const setTimeOut = (e) => dispatch => {
	dispatch({
		type: 'TIMEOUT',
		payload: e,
	})
}