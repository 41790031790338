export const universalMenuV2 = [
    {
        name: '1',
        type: 'viewable',
        label: 'Dashboard',
        show: false,
        active: false,
        content: [
            { name: 'Dashboard', type: 'viewable', label: `Main Dashboard`, to: '/dashboard' },
            { name: 'Library Dashboard', type: 'viewable', label: 'Librarian Dashboard', to: '/libraryDashboard' },
            { name: '', type: 'viewable', label: `Admission Report`, to: '/Admission' },
        ]
    },
    {
        name: '2',
        type: 'viewable',
        label: 'Admissions',
        show: false,
        active: false,
        content: [
            {
                name: '21', type: 'viewable',
                label: 'Student Registrations',
                show: false,
                active: false,
                content: [
                    { name: 'Registrations', type: 'addable', label: 'New', to: '/registrationsAdd' },
                    { name: 'Registrations', type: 'importable', label: 'Bulk Upload', to: '/registrationsImport' }
                ]
            },
            {
                name: '22',
                type: 'viewable',
                label: 'Reports',
                show: false,
                active: false,
                content: [
                    // { name: 'Registrations', type: 'viewable', label: 'New Student Registration', to: '/admissions' },
                    // { name: '', type: 'viewable', label: 'Bulk Upload List', to: '/admissions' },
                    { name: 'Registrations', type: 'viewable', label: 'New Registrations List', to: '/registrationsList' },
                    { name: 'Admissions', type: 'viewable', label: 'Admission Confirmed List', to: '/admissions' },
                    { name: 'Slide', type: 'viewable', label: 'Slided Students', to: '/slidedList' },
                    { name: 'Students', type: 'viewable', label: 'Final List of Students', to: '/students' },
                    // { name: 'Admissions', type: 'viewable', label: 'List of Students Existed', to: '/admissions' }
                ]
            },
            {
                name: 'slide',
                type: 'viewable',
                label: 'Sliding',
                show: false,
                active: false,
                content: [
                    { name: 'Slide', type: 'viewable', label: 'Sliding Status', to: '/slidedList' },
                    { name: 'Slide', type: 'Addable', label: 'Request Sliding', to: '/slide' },
                ]
            },
            { name: 'Admissions', type: 'viewable', label: 'Generate Roll Numbers', to: '/rollNumbers' }
        ]
    },
    {
        name: '3',
        type: 'viewable',
        label: 'Academics',
        show: false,
        active: false,
        content: [
            {
                name: '31',
                type: 'viewable',
                label: 'L.M.S',
                show: false,
                active: false,
                // content: [
                //     {
                //         name: '311',
                //         type: 'viewable',
                //         label: 'Syllabus',
                //         show: false,
                //         active: false,
                //         content: [
                //             { name: 'Syllabus', type: 'viewable', label: 'List', to: '/syllabusList' },
                //             { name: 'Syllabus', type: 'addable', label: 'Add', to: '/syllabusAdd' },
                //         ]
                //     },
                //     {
                //         name: '312',
                //         type: 'viewable',
                //         label: 'Materials',
                //         show: false,
                //         active: false,
                //         content: [
                //             { name: 'Materials', type: 'viewable', label: 'List', to: '/materialsList' },
                //             { name: 'Materials', type: 'addable', label: 'Add', to: '/materialsAdd' },
                //         ]
                //     },
                // ]
                to: 'lms.asram.in'
            },
            {
                name: '313',
                type: 'viewable',
                label: 'Workshops',
                show: false,
                active: false,
                content: [
                    { name: 'Workshops', type: 'viewable', label: 'List', to: '/workshopsList' },
                    { name: 'Workshops', type: 'viewable', label: 'Registrations List', to: '/workshopsRegisteredList' },
                    { name: 'Workshops', type: 'addable', label: 'Add', to: '/workshopsAdd' },
                ]
            },
            { name: 'Awards', type: 'viewable', label: 'Awards', to: '/awards' },
            {
                name: '314',
                type: 'viewable',
                label: 'Marks',
                show: false,
                active: false,
                content: [
                    { name: 'Marks', type: 'viewable', label: 'List', to: '/marksList' },
                    { name: 'Marks', type: 'addable', label: 'Add', to: '/marksAdd' },
                ]
            },
            {
                name: '315',
                type: 'viewable',
                label: 'Attendance',
                show: false,
                active: false,
                content: [
                    { name: 'Attendance', type: 'viewable', label: 'List', to: '/attendanceList' },
                    { name: 'Attendance', type: 'addable', label: 'Add', to: '/attendanceAdd' },
                ]
            },
            { name: 'Payments', type: 'addable', label: 'Fine Creation', to: '/paymentsAdd' },
            { name: 'FAQs', type: 'viewable', label: `FAQ's`, to: '/faqs' }
        ]
    },
    {
        name: '4',
        type: 'viewable',
        label: 'Accounts',
        show: false,
        active: false,
        content: [
            {
                name: '41',
                type: 'viewable',
                label: 'Ledger',
                show: false,
                active: false,
                content: [
                    { name: 'Ledger', type: 'viewable', label: 'Head Wise Ledger', to: '/headLedger' },
                    { name: 'Ledger', type: 'viewable', label: 'Student Ledger', to: '/paymentsSummary' },
                    { name: 'Ledger', type: 'viewable', label: 'Fee Due', to: '/feeDue'}
                ]
            },
            {
                name: '42',
                type: 'viewable',
                label: 'Transactions',
                show: false,
                active: false,
                content: [
                    // { name: 'Payments', type: 'viewable', label: 'Import Receipt', to: '/payments' },
                    // { name: '', type: 'viewable', label: 'Export Receipts', to: '/payments' },
                    { name: 'Payments', type: 'viewable', label: 'Receipts', to: '/payments' },
                    { name: 'Refund', type: 'viewable', label: 'Refunds', to: '/refund' },
                    { name: 'Payments', type: 'exportable', label: 'Export payments', to: '/exportPayments'}
                ]
            },
        ]
    },
    {
        name: '5',
        type: 'viewable',
        label: 'Masters',
        show: false,
        active: false,
        content: [
            { name: 'Course', type: 'viewable', label: 'Courses', to: '/course' },
            { name: 'Subjects', type: 'viewable', label: 'Subjects', to: '/subjects' },
            { name: 'Documents', type: 'viewable', label: 'Documents', to: '/documents' },
            { name: '', type: 'viewable', label: `Approval Flow`, to: '/ApprovalFlow' },
            { name: 'Roles', type: 'viewable', label: 'Roles & Permissions', to: '/roles' },
            // { name: 'Course', type: 'viewable', label: `Courses`, to: '/course' },

            {
                name: '51',
                type: 'viewable',
                label: 'College',
                show: false,
                active: false,
                content: [
                    { name: 'College', type: 'viewable', label: 'List', to: '/collegeList' },
                    { name: 'College', type: 'addable', label: 'Add', to: '/collegeAdd' },
                ]
            },
            {
                name: '52',
                type: 'viewable',
                label: 'Fees Master',
                show: false,
                active: false,
                content: [
                    { name: 'Feetype', type: 'viewable', label: 'Fee Type', to: '/feeType' },
                    { name: 'Fee', type: 'viewable', label: 'Fee Particulars', to: '/fee' },
                    { name: 'HostelFee', type: 'viewable', label: 'Hostel Fee', to: '/HostelFee' },
                    { name: 'MQ Payments', type: 'viewable', label: 'MQ Payments', to: '/MQpayments' },
                ]
            },
            {
                name: '53',
                type: 'viewable',
                label: 'Users',
                show: false,
                active: false,
                content: [
                    { name: 'Users', type: 'viewable', label: 'List User', to: '/usersList' },
                    { name: 'Users', type: 'addable', label: 'Add User', to: '/usersAdd' },
                    // { name: '', type: 'viewable', label: 'Delete User', to: '/users' },
                ]
            },
            {
                name: '54',
                type: 'viewable',
                label: 'Professors',
                show: false,
                active: false,
                content: [
                    { name: 'Professors', type: 'viewable', label: 'List', to: '/professorsList' },
                    { name: 'Professors', type: 'addable', label: 'Add', to: '/professorsAdd' },
                    // { name: '', type: 'viewable', label: 'Delete User', to: '/users' },
                ]
            },
            { name: '', type: 'viewable', label: `All Downloads`, to: '/downloads' },
        ]
    },
    {
        name: '6',
        type: 'viewable',
        label: 'Communications',
        show: false,
        active: false,
        content: [
            {
                name: '61',
                type: 'viewable',
                label: 'Notifications',
                show: false,
                active: false,
                content: [
                    { name: 'Notifications', type: 'viewable', label: 'List', to: '/notificationsList' },
                    { name: 'Notifications', type: 'addable', label: 'Add', to: '/notificationsAdd' },
                ]
            },
            {
                name: '62',
                type: 'viewable',
                label: 'Noticeboard',
                show: false,
                active: false,
                content: [
                    { name: 'Noticeboard', type: 'viewable', label: 'List', to: '/noticeboardList' },
                    { name: 'Noticeboard', type: 'addable', label: 'Add', to: '/noticeboardAdd' },
                ]
            },
            // { name: '', type: 'viewable', label: `Mobile alerts`, to: '/admissions' }
        ]
    },
    {
        name: '7',
        type: 'viewable',
        label: 'Settings',
        show: false,
        active: false,
        content: [
            // { name: 'admin', type: 'viewable', label: `Settings`, to: '/setting' },
            { name: 'admin', type: 'viewable', label: `Logs`, to: '/logs' },
            { name: '', type: 'viewable', label: 'Change Password', to: '/changepassword' },
            { name: '', type: 'viewable', label: `Active Users`, to: '/activeusers' },
            {
                name: '71',
                type: 'viewable',
                label: 'Google Auth',
                show: false,
                active: false,
                content: [
                    { name: 'OAuth', type: 'viewable', label: 'OAuth', to: '' },
                ]
            },
        ]
    },
    {
        name: '8',
        type: 'viewable',
        label: 'Library',
        show: false,
        active: false,
        content: [
            // { name: '', type: 'viewable', label: 'Dashboard', to: '/libraryDashboard' },
            {
                name: '81', type: 'viewable',
                label: 'Report',
                show: false,
                active: false,
                content: [
                    { name: 'Books', type: 'viewable', label: 'List of Books', to: '/book' },
                    { name: 'Journals', type: 'viewable', label: 'List of Journals', to: '/journal' },
                    // { name: '', type: 'viewable', label: 'List of Online Journals', to: '/journal' }
                ]
            },
            {
                name: '82',
                type: 'viewable',
                label: 'Catalogue',
                show: false,
                active: false,
                content: [
                    { name: 'Books', type: 'addable', label: 'Add Book', to: '/bookAdd' },
                    { name: 'Books', type: 'importable', label: 'Import Book', to: '/bookImport' },
                    { name: 'Journals', type: 'addable', label: 'Add Journal', to: '/journalAdd' },
                    { name: 'Journals', type: 'importable', label: 'Import Journal', to: '/journalImport' },
                    // { name: '', type: 'viewable', label: 'Add Online Journal', to: '/journal' },
                    // { name: '', type: 'viewable', label: 'Import Online Journal', to: '/journal' }
                ]
            },
            {
                name: '83',
                type: 'viewable',
                label: 'Exports',
                show: false,
                active: false,
                content: [
                    { name: 'Books', type: 'exportable', label: 'Export Book', to: '/bookExport' },
                    { name: 'Journals', type: 'exportable', label: 'Export Journal', to: '/journalExport' },
                    // { name: '', type: 'viewable', label: 'Export Online Journal', to: '/book' },
                    // { name: '', type: 'viewable', label: 'Export Inventory', to: '/book' }
                ]
            },
            {
                name: '84',
                type: 'viewable',
                label: 'Circulations',
                show: false,
                active: false,
                content: [
                    { name: 'Requested Books', type: 'viewable', label: 'Requested Books', to: '/requestBooks' },
                    { name: 'Requested Journals', type: 'viewable', label: 'Requested Journal', to: '/requestJournals' },
                    { name: 'Request TransferBooks', type: 'viewable', label: 'Request Transfer Books', to: '/requestTransferBooks' },

                    { name: 'Assign Book', type: 'viewable', label: 'Assign Book', to: '/assignBooks' },
                    { name: 'Assign Journals', type: 'viewable', label: 'Assign Journal', to: '/assignJournals' },
                    { name: 'Assign TransferBooks', type: 'viewable', label: 'Assign Transfer Books', to: '/issueTransferBooks' },

                    { name: 'Issues', type: 'viewable', label: 'Issue of books', to: '/issueBooks' },
                    { name: 'Issued Journals', type: 'viewable', label: 'Issue of Journals', to: '/issueJournals' },

                    { name: 'Damaged book', type: 'viewable', label: 'Damaged Book', to: '/damageBooks' },
                    { name: 'Damaged Journals', type: 'viewable', label: 'Damaged Journals', to: '/damageJournals' },

                    { name: 'Recommended Books', type: 'viewable', label: 'Recommend Books', to: '/recommendBook' },
                ]
            },
        ]
    },
    { name: 'admin', type: 'viewable', label: `Student 360`, to: '/advancedsearch' },
];

export const universalStudentMenuV2 = [
    { name: 'Dashboard', type: 'viewable', label: 'Dashboard', to: '/dashboard' },
    { name: 'Admissions', type: 'viewable', label: 'Admissions', to: '/admissions' },
    {
        name: '6',
        type: 'viewable',
        label: 'Communications',
        show: false,
        active: false,
        content: [
            {
                name: '61',
                type: 'viewable',
                label: 'Notifications',
                show: false,
                active: false,
                content: [
                    { name: 'Notifications', type: 'viewable', label: 'List', to: '/notificationsList' },
                    { name: 'Notifications', type: 'addable', label: 'Add', to: '/notificationsAdd' },
                ]
            },
            {
                name: '62',
                type: 'viewable',
                label: 'Noticeboard',
                show: false,
                active: false,
                content: [
                    { name: 'Noticeboard', type: 'viewable', label: 'List', to: '/noticeboardList' },
                    { name: 'Noticeboard', type: 'addable', label: 'Add', to: '/noticeboardAdd' },
                ]
            },
            // { name: '', type: 'viewable', label: `Mobile alerts`, to: '/admissions' }
        ]
    },
    {
        name: '3',
        type: 'viewable',
        label: 'Academics',
        show: false,
        active: false,
        content: [
            {
                name: '31',
                type: 'viewable',
                label: 'L.M.S',
                show: false,
                active: false,
                content: [
                    {
                        name: '311',
                        type: 'viewable',
                        label: 'Syllabus',
                        show: false,
                        active: false,
                        content: [
                            { name: 'Syllabus', type: 'viewable', label: 'List', to: '/syllabusList' },
                            { name: 'Syllabus', type: 'addable', label: 'Add', to: '/syllabusAdd' },
                        ]
                    },
                    {
                        name: '312',
                        type: 'viewable',
                        label: 'Materials',
                        show: false,
                        active: false,
                        content: [
                            { name: 'Materials', type: 'viewable', label: 'List', to: '/materialsList' },
                            { name: 'Materials', type: 'addable', label: 'Add', to: '/materialsAdd' },
                        ]
                    },
                ]
            },
            {
                name: '313',
                type: 'viewable',
                label: 'Workshops',
                show: false,
                active: false,
                content: [
                    { name: 'Workshops', type: 'viewable', label: 'List', to: '/workshopsList' },
                    { name: 'Workshops', type: 'viewable', label: 'Registrations List', to: '/workshopsRegisteredList' },
                    { name: 'Workshops', type: 'addable', label: 'Add', to: '/workshopsAdd' },
                ]
            },
            { name: 'Awards', type: 'viewable', label: 'Awards', to: '/awards' },
            {
                name: '314',
                type: 'viewable',
                label: 'Marks',
                show: false,
                active: false,
                content: [
                    { name: 'Marks', type: 'viewable', label: 'List', to: '/marksList' },
                    { name: 'Marks', type: 'addable', label: 'Add', to: '/marksAdd' },
                ]
            },
            {
                name: '315',
                type: 'viewable',
                label: 'Attendance',
                show: false,
                active: false,
                content: [
                    { name: 'Attendance', type: 'viewable', label: 'List', to: '/attendanceList' },
                    { name: 'Attendance', type: 'addable', label: 'Add', to: '/attendanceAdd' },
                ]
            },
            { name: 'Payments', type: 'addable', label: 'Fine Creation', to: '/paymentsAdd' },
            { name: 'FAQs', type: 'viewable', label: `FAQ's`, to: '/faqs' }
        ]
    },
    {
        name: '5',
        type: 'viewable',
        label: 'Masters',
        show: false,
        active: false,
        content: [
            { name: 'Course', type: 'viewable', label: 'Courses', to: '/course' },
            { name: 'Subjects', type: 'viewable', label: 'Subjects', to: '/subjects' },
            { name: 'Documents', type: 'viewable', label: 'Documents', to: '/documents' },
            { name: '', type: 'viewable', label: `Approval Flow`, to: '/ApprovalFlow' },
            { name: 'Roles', type: 'viewable', label: 'Roles & Permissions', to: '/roles' },
            // { name: 'Course', type: 'viewable', label: `Courses`, to: '/course' },

            {
                name: '51',
                type: 'viewable',
                label: 'College',
                show: false,
                active: false,
                content: [
                    { name: 'College', type: 'viewable', label: 'List', to: '/collegeList' },
                    { name: 'College', type: 'addable', label: 'Add', to: '/collegeAdd' },
                ]
            },
            {
                name: '52',
                type: 'viewable',
                label: 'Fees Master',
                show: false,
                active: false,
                content: [
                    { name: 'Feetype', type: 'viewable', label: 'Fee Type', to: '/feeType' },
                    { name: 'Fee', type: 'viewable', label: 'Fee Particulars', to: '/fee' },
                    { name: 'HostelFee', type: 'viewable', label: 'Hostel Fee', to: '/HostelFee' },
                    { name: 'MQ Payments', type: 'viewable', label: 'MQ Payments', to: '/MQpayments' },
                ]
            },
            {
                name: '53',
                type: 'viewable',
                label: 'Users',
                show: false,
                active: false,
                content: [
                    { name: 'Users', type: 'viewable', label: 'List User', to: '/usersList' },
                    { name: 'Users', type: 'addable', label: 'Add User', to: '/usersAdd' },
                    // { name: '', type: 'viewable', label: 'Delete User', to: '/users' },
                ]
            },
            {
                name: '54',
                type: 'viewable',
                label: 'Professors',
                show: false,
                active: false,
                content: [
                    { name: 'Professors', type: 'viewable', label: 'List', to: '/professorsList' },
                    { name: 'Professors', type: 'addable', label: 'Add', to: '/professorsAdd' },
                    // { name: '', type: 'viewable', label: 'Delete User', to: '/users' },
                ]
            },
            { name: '', type: 'viewable', label: `All Downloads`, to: '/downloads' },
        ]
    },
    {
        name: '8',
        type: 'viewable',
        label: 'Library',
        show: false,
        active: false,
        content: [
            // { name: '', type: 'viewable', label: 'Dashboard', to: '/libraryDashboard' },
            {
                name: '81', type: 'viewable',
                label: 'Report',
                show: false,
                active: false,
                content: [
                    { name: 'Books', type: 'viewable', label: 'List of Books', to: '/book' },
                    { name: 'Journals', type: 'viewable', label: 'List of Journals', to: '/journal' },
                    // { name: '', type: 'viewable', label: 'List of Online Journals', to: '/journal' }
                ]
            },
            {
                name: '82',
                type: 'viewable',
                label: 'Catalogue',
                show: false,
                active: false,
                content: [
                    { name: 'Books', type: 'addable', label: 'Add Book', to: '/bookAdd' },
                    { name: 'Books', type: 'importable', label: 'Import Book', to: '/bookImport' },
                    { name: 'Journals', type: 'addable', label: 'Add Journal', to: '/journalAdd' },
                    { name: 'Journals', type: 'importable', label: 'Import Journal', to: '/journalImport' },
                    // { name: '', type: 'viewable', label: 'Add Online Journal', to: '/journal' },
                    // { name: '', type: 'viewable', label: 'Import Online Journal', to: '/journal' }
                ]
            },
            {
                name: '83',
                type: 'viewable',
                label: 'Exports',
                show: false,
                active: false,
                content: [
                    { name: '', type: 'viewable', label: 'Export Book', to: '/book' },
                    { name: '', type: 'viewable', label: 'Export Journal', to: '/journal' },
                    // { name: '', type: 'viewable', label: 'Export Online Journal', to: '/book' },
                    // { name: '', type: 'viewable', label: 'Export Inventory', to: '/book' }
                ]
            },
            {
                name: '84',
                type: 'viewable',
                label: 'Circulations',
                show: false,
                active: false,
                content: [
                    { name: 'Requested Books', type: 'viewable', label: 'Requested Books', to: '/requestBooks' },
                    { name: 'Requested Journals', type: 'viewable', label: 'Requested Journal', to: '/requestJournals' },
                    { name: 'Request TransferBooks', type: 'viewable', label: 'Request Transfer Books', to: '/requestTransferBooks' },

                    { name: 'Assign Book', type: 'viewable', label: 'Assign Book', to: '/assignBooks' },
                    { name: 'Assign Journals', type: 'viewable', label: 'Assign Journal', to: '/assignJournals' },
                    { name: 'Assign TransferBooks', type: 'viewable', label: 'Assign Transfer Books', to: '/issueTransferBooks' },

                    { name: 'Issues', type: 'viewable', label: 'Issue of books', to: '/issueBooks' },
                    { name: 'Issued Journals', type: 'viewable', label: 'Issue of Journals', to: '/issueJournals' },

                    { name: 'Damaged book', type: 'viewable', label: 'Damaged Book', to: '/damageBooks' },
                    { name: 'Damaged Journals', type: 'viewable', label: 'Damaged Journals', to: '/damageJournals' },

                    { name: 'Recommended Books', type: 'viewable', label: 'Recommend Books', to: '/recommendBook' },
                ]
            },
        ]
    },
    {
        name: '1',
        type: 'viewable',
        label: 'Payments',
        show: false,
        active: false,
        content: [
            { name: 'Transactions', type: 'viewable', label: 'Transactions', allow: true, to: '/transactions' },
            { name: 'Payments Due', type: 'viewable', label: `Payments Due`, allow: true, to: '/payment-status' },
        ]
    },
    {
        name: '1',
        type: 'viewable',
        label: 'Grievance',
        show: false,
        active: false,
        content: [
            { name: 'Academic', type: 'viewable', label: 'Academic', allow: true, to: '/academic' },
            { name: 'Non Academic', type: 'viewable', label: `Non Academic`, allow: true, to: '/nonAcademic' },
        ]
    },
]