import { lazy } from 'react';

const Dashboard = lazy(() => import('./university/Dashboard/Dashboard'));
const Professors = lazy(() => import('./university/Professors/Professors'));
const Students = lazy(() => import('./university/Students/Students'));
// const Admissions = lazy(() => import('./university/Admissions/Registrations'));

const RegistrationsList = lazy(() => import('./university/Registrations/List'));
const RegistrationsAdd = lazy(() => import('./university/Registrations/Add'));
const RegistrationsImport = lazy(() => import('./university/Registrations/Imports'));

const Transactions = lazy(() => import('./university/Transactions/Transactions'));
const FAQs = lazy(() => import('./university/FAQs/FAQs'));
const Course = lazy(() => import('./university/Course/Course'));
const Documents = lazy(() => import('./university/Course/Documents'));
const Subjects = lazy(() => import('./university/Course/Subjects'));
const Materials = lazy(() => import('./university/Materials/Materials'));
const Marks = lazy(() => import('./university/Marks/Marks'));
const Awards = lazy(() => import('./university/Awards/Awards'));
const Payments = lazy(() => import('./admin/Payments/Payments'));
const ExportPayments = lazy(() => import('./admin/Payments/ExportPayments'));
const PaymentStatus = lazy(() => import('./university/PaymentStatus/PaymentStatus'));
const Noticeboard = lazy(() => import('./admin/Noticeboard/Noticeboard'));
const Notification = lazy(() => import('./admin/Notifications/Notification'));
const NewProfile = lazy(() => import('./admin/NewProfile/NewProfile'));
const Attendance = lazy(() => import('./admin/Attendance/Attendance'));
// const Setting = lazy(() => import('./admin/Setting/Setting'));
// const Login = lazy(() => import('./Authentication/login'));
// const Signup = lazy(() => import('./Authentication/signup'));
// const ForgotPassword = lazy(() => import('./Authentication/forgotpassword'));
// const NotFound = lazy(() => import('./Authentication/404'));
// const InternalServer = lazy(() => import('./Authentication/500'));
const ChangePassword = lazy(() => import('./Authentication/changePassword'));
const NeedHelp = lazy(() => import('./Authentication/needHelp'));
const Roles = lazy(() => import('./admin/Roles/Roles'));
const ApprovalFlow = lazy(() => import('./admin/ApprovalFlow/ApprovalFlow'));
const Users = lazy(() => import('./admin/Users/Users'));
const Fee = lazy(() => import('./admin/Fee/Fee'));
const Workshop = lazy(() => import('./admin/Workshop/Workshop'));
const Syllabus = lazy(() => import('./university/Syllabus'));
const Logs = lazy(() => import('./admin/Logs'));
const Library = lazy(() => import('./admin/Library/Library'));
const Journal = lazy(() => import('./admin/Journal/Journal'));
const Issue = lazy(() => import('./admin/Library/Issue'));
const Dashbord = lazy(() => import('./admin/Library/Dashbord'));
const Assignbook = lazy(() => import('./admin/Library/Assignbook'));
// const CloseWindow = lazy(() => import('./Authentication/closeWindow'));
const AdvancedSearch = lazy(() => import('./admin/AdvancedSearch'));
const ApprovedStudents = lazy(() => import('./university/Students/ApprovedStudents'));
const MQpayments = lazy(() => import('./admin/MQpayments/MQpayments'));
const ActiveUsers = lazy(() => import('./admin/ActiveUsers/Active'));
const PaymentsSummary = lazy(() => import('./admin/PaymentsSummary'));
const AdmissionReport = lazy(() => import('./admin/AdmissionReport/admissionrepo'));
const Return = lazy(() => import('./admin/Library/Return'));
const Damage = lazy(() => import('./admin/Library/Damage'));
const Request = lazy(() => import('./admin/Library/Request'));
const Slide = lazy(() => import('./university/Students/Slide'));
const FeeType = lazy(() => import('./admin/Fee/FeeType'));
const HostelFee = lazy(() => import('./admin/Fee/HostelFee'));
const Downloads = lazy(() => import('./university/Downloads'));
const Refund = lazy(() => import('./admin/Payments/Refund'));
const IssueJournal = lazy(() => import('./admin/Journal/IssueJournal'));
const ReturnJournal = lazy(() => import('./admin/Journal/ReturnJournal'));
const DamageJournal = lazy(() => import('./admin/Journal/DamageJournal'));
const AssignJournal = lazy(() => import('./admin/Journal/AssignJournal'));
const RequestJournal = lazy(() => import('./admin/Journal/RequestJournal'));
const HeadLedger = lazy(() => import('./admin/PaymentsSummary/HeadLedger'));
const FeeDue = lazy(() => import('./admin/PaymentsSummary/FeeDue'));
const AssignTransfer = lazy(() => import('./admin/Library/AssignTransfer'));
const RequestTransfer = lazy(() => import('./admin/Library/RequestTransfer'));
const RecommendBook = lazy(() => import('./admin/Library/RecommendBook'));
const SlideStudent = lazy(() => import('./university/Admissions/SlideStudent'));
const GenerateRollNumbers = lazy(() => import('./university/Admissions/GenerateRollNumbers'));
const AddSyllabus = lazy(() => import('./university/Syllabus/AddSyllabus'));
const AddMaterials = lazy(() => import('./university/Materials/AddMaterials'));
const AddWorkshop = lazy(() => import('./admin/Workshop/AddWorkshop'));
const AddMarks = lazy(() => import('./university/Marks/AddMarks'));
const AddAttendance = lazy(() => import('./admin/Attendance/AddAttendance'));
const AddNoticeBoard = lazy(() => import('./admin/Noticeboard/AddNoticeBoard'));
const AddNotifications = lazy(() => import('./admin/Notifications/AddNotifications'));
const AddUsers = lazy(() => import('./admin/Users/AddUsers'));
const AddProfessors = lazy(() => import('./university/Professors/AddProfessors'));
const AddPayments = lazy(() => import('./admin/Payments/AddPayments'));
const AddBook = lazy(() => import('./admin/Library/AddBook'));
const ImportBook = lazy(() => import('./admin/Library/ImportBook'));
const AddJournal = lazy(() => import('./admin/Journal/AddJournal'));
const ImportJournal = lazy(() => import('./admin/Journal/ImportJournal'));
const RegisteredWorkshops = lazy(() => import('./admin/Workshop/RegisteredWorkshops'));
const CollegeList = lazy(() => import('./admin/College/CollegeList'));
const AddCollege = lazy(() => import('./admin/College/AddCollege'));
const ExportBook = lazy(() => import('./admin/Library/ExportBook'));
const ExportJournal = lazy(() => import('./admin/Journal/ExportJournal'));

const Academic = lazy(() => import('./university/Grievance/Academic'));
const NonAcademic = lazy(() => import('./university/Grievance/NonAcademic'));


const libraryRoutes = [
  { path: "/libraryDashboard", name: 'Library Dashboard', exact: true, type: "viewable", component: Dashbord },

  { path: "/book", name: 'Books', exact: true, type: "viewable", component: Library },
  { path: "/bookAdd", name: 'Books', exact: true, type: "addable", component: AddBook },
  { path: "/bookImport", name: 'Books', exact: true, type: "importable", component: ImportBook },
  { path: "/bookExport", name: 'Books', exact: true, type: "exportable", component: ExportBook },
  { path: "/requestBooks", name: "Requested Books", exact: true, type: "viewable", component: Request, },
  { path: "/assignBooks", name: 'Assign Book', exact: true, type: "viewable", component: Assignbook },
  { path: "/issueBooks", name: 'Issues', exact: true, type: "viewable", component: Issue },
  { path: "/returnBooks", name: 'return', exact: true, type: "viewable", component: Return },
  { path: "/damageBooks", name: 'Damaged book', exact: true, type: "viewable", component: Damage },
  { path: "/recommendBook", name: 'Recommended Books', exact: true, type: "viewable", component: RecommendBook },

  { path: "/journal", name: 'Journals', exact: true, type: "viewable", component: Journal },
  { path: "/journalAdd", name: 'Journals', exact: true, type: "addable", component: AddJournal },
  { path: "/journalImport", name: 'Journals', exact: true, type: "importable", component: ImportJournal },
  { path: "/journalExport", name: 'Journals', exact: true, type: "exportable", component: ExportJournal },
  { path: "/requestJournals", name: "Requested Journals", exact: true, type: "viewable", component: RequestJournal, },
  { path: "/assignJournals", name: 'Assign Journals', exact: true, type: "viewable", component: AssignJournal },
  { path: "/issueJournals", name: 'Issued Journals', exact: true, type: "viewable", component: IssueJournal },
  { path: "/returnJournals", name: 'return Journals', exact: true, type: "viewable", component: ReturnJournal },
  { path: "/damageJournals", name: 'Damaged Journals', exact: true, type: "viewable", component: DamageJournal },

  { path: "/requestTransferBooks", name: "Request TransferBooks", exact: true, type: "viewable", component: RequestTransfer, },
  { path: "/issueTransferBooks", name: 'Assign TransferBooks', exact: true, type: "viewable", component: AssignTransfer },
];

const commonRoutes = [
  // { path: "/login", name: "login", exact: true, type: "Tables", component: Login, }, 
  // { path: "/closeWindow", name: "close", exact: true, type: "Tables", component: CloseWindow, }, 
  // { path: "/signup", name: "signup", exact: true, type: "Tables", component: Signup, }, 
  // { path: "/forgotpassword", name: "forgotpassword", exact: true, type: "Tables", component: ForgotPassword, }, 
  // { path: "/notfound", name: "notfound", exact: true, type: "Tables", component: NotFound, }, 
  // { path: "/internalserver", name: "internalserver", exact: true, type: "Tables", component: InternalServer, },
  { path: "/changePassword", name: "changePassword", exact: true, type: "viewable", component: ChangePassword, },
];

const registrations = [
  { path: "/registrationsList", name: "Registrations", exact: true, type: "viewable", component: RegistrationsList, },
  { path: "/registrationsAdd", name: "Registrations", exact: true, type: "addable", component: RegistrationsAdd, },
  { path: "/registrationsImport", name: "Registrations", exact: true, type: "importable", component: RegistrationsImport, },
];

const admissions = [
  { path: "/slide", name: "Slide", exact: true, type: "viewable", component: SlideStudent, },
  { path: "/slidedList", name: "Slide", exact: true, type: "viewable", component: Slide, },
  { path: "/rollNumbers", name: "Admissions", exact: true, type: "viewable", component: GenerateRollNumbers, },
]

const LMS = [
  { path: "/subjects", name: "Subjects", exact: true, type: "viewable", component: Subjects, },

  { path: "/syllabusList", name: "Syllabus", exact: true, type: "viewable", component: Syllabus, },
  { path: "/syllabusAdd", name: "Syllabus", exact: true, type: "addable", component: AddSyllabus, },

  { path: "/course", name: "Course", exact: true, type: "viewable", component: Course, },

  { path: "/materialsList", name: "Materials", exact: true, type: "viewable", component: Materials, },
  { path: "/materialsAdd", name: "Materials", exact: true, type: "addable", component: AddMaterials, },

  { path: "/awards", name: "Awards", exact: true, type: "viewable", component: Awards, },

  { path: "/workshopsList", name: "Workshops", exact: true, type: "viewable", component: Workshop, },
  { path: "/workshopsRegisteredList", name: "Workshops", exact: true, type: "viewable", component: RegisteredWorkshops, },
  { path: "/workshopsAdd", name: "Workshops", exact: true, type: "addable", component: AddWorkshop, },

  { path: "/marksList", name: "Marks", exact: true, type: "viewable", component: Marks, },
  { path: "/marksAdd", name: "Marks", exact: true, type: "addable", component: AddMarks, },

  { path: "/attendanceList", name: "Attendance", exact: true, type: "viewable", component: Attendance, },
  { path: "/attendanceAdd", name: "Attendance", exact: true, type: "addable", component: AddAttendance, },

];

const masters = [
  { path: "/approvalFlow", name: "ApprovalFlow", exact: true, type: "viewable", component: ApprovalFlow, },
  { path: "/roles", name: "Roles", exact: true, type: "viewable", component: Roles, },

  { path: "/collegeList", name: "College", exact: true, type: "viewable", component: CollegeList, },
  { path: "/collegeAdd", name: "College", exact: true, type: "addable", component: AddCollege, },
  
  { path: '/feeType', name: 'Feetype', exact: true, type: 'viewable', component: FeeType },
  { path: '/hostelFee', name: 'HostelFee', exact: true, type: 'viewable', component: HostelFee },
  { path: "/fee", name: "Fee", exact: true, type: "viewable", component: Fee, },
  { path: "/MQpayments", name: "MQ Payments", exact: true, type: "viewable", component: MQpayments, },

  { path: "/usersList", name: "Users", exact: true, type: "viewable", component: Users, },
  { path: "/usersAdd", name: "Users", exact: true, type: "addable", component: AddUsers, },

  { path: "/professorsList", name: "Professors", exact: true, type: "viewable", component: Professors, },
  { path: "/professorsAdd", name: "Professors", exact: true, type: "addable", component: AddProfessors, },

];

const communications = [
  
  { path: "/noticeboardList", name: "Noticeboard", exact: true, type: "viewable", component: Noticeboard, },
  { path: "/noticeboardAdd", name: "Noticeboard", exact: true, type: "addable", component: AddNoticeBoard, },

  { path: "/notificationsList", name: "Notifications", exact: true, type: "viewable", component: Notification, },
  { path: "/notificationsAdd", name: "Notifications", exact: true, type: "addable", component: AddNotifications, },
];

const Routes = [
  { path: "/", name: "Dashboard", exact: true, type: "viewable", component: Dashboard, }, 
  { path: "/dashboard", name: "Dashboard", exact: true, type: "viewable", component: Dashboard, }, 
  ...registrations,
  ...admissions,
  ...LMS,
  ...masters,
  ...communications,
  { path: "/needHelp", name: "NeedHelp", exact: true, type: "viewable", component: NeedHelp, },
  { path: "/logs", name: "Logs", exact: true, type: "viewable", component: Logs, },
  // { path: '/slide', name: 'Slide', exact: true, type: 'Slide', component: Slide },
  // { path: "/setting", name: "setting", exact: true, type: "setting", component: Setting, },
  { path: "/paymentsSummary", name: "Ledger", exact: true, type: "viewable", component: PaymentsSummary, },
  { path: "/headLedger", name: "Ledger", exact: true, type: "viewable", component: HeadLedger, },
  { path: "/feeDue", name: "Fee Due", exact: true, type: "viewable", component: FeeDue, },
  { path: "/newprofile", name: "NewProfile", exact: true, type: "viewable", component: NewProfile, },
  { path: "/payments", name: "Payments", exact: true, type: "viewable", component: Payments, },
  { path: "/paymentsAdd", name: "Payments", exact: true, type: "addable", component: AddPayments, },
  { path: "/exportPayments", name: "Payments", exact: true, type: "exportable", component: ExportPayments, },
  { path: "/Admission", name: "Admission", exact: true, type: "viewable", component: AdmissionReport, },
  { path: '/downloads', name: 'Downloads', exact: true, type: 'viewable', component: Downloads },
  { path: "/ActiveUsers", name: "ActiveUsers", exact: true, type: "viewable", component: ActiveUsers, },
  { path: "/payment-status", name: "PaymentStatus", exact: true, type: "viewable", component: PaymentStatus, },
  { path: "/refund", name: "Refund", exact: true, type: "viewable", component: Refund, },
  { path: "/documents", name: "Documents", exact: true, type: "viewable", component: Documents, },
  { path: "/admissions", name: "Admissions", exact: true, type: "viewable", component: Students, },
  // { path: "/registrations", name: "Registrations", exact: true, type: "Registrations", component: Admissions, },
  { path: "/students", name: "Students", exact: true, type: "viewable", component: ApprovedStudents, },
  { path: "/transactions", name: "Transactions", exact: true, type: "viewable", component: Transactions, },
  { path: "/faqs", name: "FAQs", exact: true, type: "viewable", component: FAQs, },
  { path: "/advancedsearch", name: "AdvancedSearch", exact: true, type: "viewable", component: AdvancedSearch, }, 

  { path: '/academic', name: 'Academic', exact: true, type: 'viewable', component: Academic},
  { path: '/nonAcademic', name: 'Non Academic', exact: true, type: 'viewable', component: NonAcademic},
  ...libraryRoutes,
  ...commonRoutes,
];

export default Routes;
