import React, { Component } from "react";
// import DataService from '../../services/DataService'
// import { useNavigate } from "react-dom";
import { validateForm } from "../../App";

import AuthService from "./../../services/AuthService";
import { Link } from "react-router-dom";
import background from "../../assets/images/gallery/login.jpg";
import logo from "../../assets/images/asramlogo.png";
import { connect } from "react-redux";
import { profileMenuAction } from "../../actions/settingsAction";
import { isLibrarian } from "../regex";
import SessionTimeout from './SessionTimeout';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userName: null,
      password: null,
      showPassword: false,
      // user: {},
      errors: {
        userName: "",
        password: "",
        formOverall: "",
      },
    };
  }

  componentDidMount() {
    this.props.profileMenuAction(false);
    // const user = AuthService.currentUser();
    // if (user) {
    //   window.location.replace("/");
    // }
  }

  handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    let errors = this.state.errors;

    errors.formOverall = "";

    switch (name) {
      case "userName":
        errors.userName =
          value === "" ? "Please enter admission/employee number." : "";
        break;
      case "password":
        errors.password = value === "" ? "Please enter password" : "";
        break;
      default:
        break;
    }
    this.setState({ ...this.state, [name]: value });
  };

  togglePassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { errors } = this.state;
    const { userName, password } = this.state;
    if (userName == null || password == null) {
      errors.formOverall = "Please enter values to login.";
      this.setState({ errors, formOverall: "" });
    } else {
      if (validateForm(this.state.errors)) {
        // Call API.
        this.setState({
          loading: true,
        });
        try {
          let data = await AuthService.login(userName, password);
          if (isLibrarian.test(data.role)) {
            window.location.replace('/#/libraryDashboard')
          } else {
            window.location.replace("/#/dashboard");
          }
        } catch (error) {
          console.log(error);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false,
            errors: {
              ...errors,
              formOverall: resMessage,
            },
          });
        }
      } else {
        console.error("Invalid form data.");
      }
    }
  };

  render() {
    const { loading, errors, showPassword } = this.state;
    return (
      <div
        className="auth option2"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="auth_left">
          <div className="">
          <h6 style={{ marginBottom: '30px', color: '#53b3d8', fontWeight: 'bold' }}>Your Gateway to Admissions, Learning Management (LMS), Library Management (ILMS), Marks, Attendance and Fee Payments.</h6>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="card-body" style={{ minWidth: "300px" }}>
                <div className="text-center pb-20">
                  <Link className="header-brand" to="/">
                    <img src={logo} alt="background" />

                    {/* <i className="fa fa-graduation-cap brand-logo"></i> */}
                  </Link>
                  <SessionTimeout />
                  <div className="card-title mt-30"></div>
                  {/* <button type="button" className="mr-1 btn btn-facebook"><i className="fa fa-facebook mr-2"></i>Facebook</button>
								<button type="button" className="btn btn-google"><i className="fa fa-google mr-2"></i>Google</button>
								<h6 className="mt-3 mb-3">Or</h6> */}
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control input-lg rounded-0 col-11"
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderColor: "#acacac",
                      }}
                      aria-describedby="emailHelp"
                      placeholder="Admission/employee number"
                      name="userName"
                      onChange={this.handleChange}
                      noValidate
                    ></input>
                    <span
                      className="input-group-text bg-white"
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderColor: "#acacac",
                        borderRadius: 0,
                      }}
                    >
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                  {errors.userName.length > 0 && (
                    <div>
                      <span className="text-warning">{errors.userName}</span>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control input-lg rounded-0 col-11"
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderColor: "#acacac",
                      }}
                      placeholder="Password"
                      name="password"
                      onChange={this.handleChange}
                      noValidate
                    />
                    <span
                      className="input-group-text bg-white"
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderColor: "#acacac",
                        borderRadius: 0,
                        cursor: "pointer",
                      }}
                      onClick={this.togglePassword}
                    >
                      <i
                        className={`fa fa-${showPassword ? "eye-slash" : "eye"
                          }`}
                      ></i>
                    </span>
                  </div>
                  {errors.password.length > 0 && (
                    <span className="text-warning">{errors.password}</span>
                  )}
                </div>

                <div className="input-group pt-3">
                  <div
                    className="col-md-6 float-left text-left p-0"
                    style={{ alignItems: "center" }}
                  >
                    <label className="pt-1 font-19">
                      <Link
                        to="/forgotPassword"
                        className="font-weight-bold small"
                      >
                        Forgot password?
                      </Link>
                    </label>
                  </div>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn btn-primary btn-block"
                      disabled={loading}
                      type="submit"
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Sign In</span>
                    </button>
                    {/* <Link className="btn btn-primary btn-block" type="submit" title="">Sign in</Link> */}
                    {/* <div className="text-muted mt-4">Don't have account yet? <Link to="/signup">Sign Up</Link></div> */}
                    {errors.formOverall.length > 0 && (
                      <span className="text-warning">{errors.formOverall}</span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileMenu: state.settings.isProfileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  profileMenuAction: (e) => dispatch(profileMenuAction(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
