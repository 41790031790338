import axios from "axios";

const AUTH_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_AUTH_SERVICE_PORT;
const USER_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_USER_SERVICE_PORT;

const getApiHeader = () => {
  const user = AuthService.currentUser();

  const apiHeader = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user?.token,
    }
  }
  return apiHeader;
}

const admission = (id, data, newUser) => {
  if (newUser) {
    return axios.post(`${USER_API}user/register`, data, getApiHeader());
  } else {
    return axios.put(`${USER_API}user/${id}`, data, getApiHeader());
  }
};

const createUser = (
  role,
  course,
  subcourse,
  category,
  firstname,
  middlename,
  lastname,
  email,
  position,
  mobilenumber,
  token
) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let gender = "male",
    dateofbirth = new Date(),
    dateofjoin = new Date(),
    paymentbillnumber = 0,
    paymentamount = 0;
  return axios.post(
    USER_API + "user/register",
    {
      role,
      course,
      category,
      subcourse,
      firstname,
      middlename,
      lastname,
      email,
      gender,
      mobilenumber,
      dateofbirth,
      dateofjoin,
      position,
      paymentbillnumber,
      paymentamount,
    },
    customConfig
  );
};

const getUserByAdmission = (admission) => {
  return axios.get(`${USER_API}user/${admission}`, getApiHeader());
};

const uploadUserProfilePic = (
  id,
  file,
  documentname,
  admissionnumber,
  documenttype,
  documentsubtype,
  token
) => {
  const customConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  const documentinfo = {
    admissionnumber,
    documentname,
    documenttype,
    documentsubtype,
  };
  formData.append("file", file, file.name);
  formData.append("documentinfo", JSON.stringify(documentinfo));

  return axios.post(
    USER_API + "user/upload/profilepic",
    formData,
    customConfig
  );
};

const createProfessor = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  if (data.id)
    return axios.put(
      USER_API + `user/${data.id}`,
      data,
      customConfig
    );
  return axios.post(
    USER_API + "user/register",
    data,
    customConfig
  );
};

const getProfessor = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  for (let i of Object.keys(search)) {
    if (search[i]) {
      filter += `${i}=${search[i]}&`;
    }
  }
  return axios.get(
    USER_API + `user/prof?${filter}page=${page}&size=${size}`,
    customConfig
  );
};

const deleteAdmission = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(USER_API + "user/" + id, customConfig);
};

const login = (username, password) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(
      AUTH_API + "auth/login",
      {
        username,
        password,
      },
      customConfig
    )
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        const expDate = new Date();
        expDate.setHours(expDate.getHours() + 5)
        localStorage.setItem("exp", expDate);
      }

      return response.data;
    });
};

const logout = (id) => {
  if (JSON.parse(localStorage.getItem("user"))?.id === id) {
    localStorage.removeItem("user");
    localStorage.removeItem("exp");
  }
  return axios
    .get(AUTH_API + `auth/logout/${id}`)
    .then((response) => {
      return response.data;
    });
};

const googleAuth = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(USER_API + "google/oauth", customConfig);
};

const validateGoogleAuth = (code, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `google/oauth/validate?code=${code}`,
    customConfig
  );
};

const resetPassword = (username) => {
  return axios.get(
    USER_API + `user/resetpassword/${username}`
  );
};

const changePassword = (username, password, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.post(
    USER_API + "user/changepassword",
    { username, password },
    customConfig
  );
};

const currentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const isUserLoggedIn = () => {
  return Boolean(localStorage.getItem("user"));
};

const getRole = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `permissions/${id}`,
    customConfig
  );
};

const getRoles = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `permissions/roles?page=1&size=40`,
    customConfig
  );
};

const getModules = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `permissions/modules?page=1&size=100`,
    customConfig
  );
};

const getUser = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  for (let i of Object.keys(search)) {
    if (search[i]) {
      filter += `${i}=${search[i]}&`;
    }
  }

  if (!search.library) {
    filter += `library=false&`;
  }
  return axios.get(
    USER_API + `user/list?${filter}page=${page}&size=${size}`,
    customConfig
  );
};
const getActiveUsers = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  for (let i of Object.keys(search)) {
    if (search[i]) {
      filter += `${i}=${search[i]}&`;
    }
  }
  return axios.get(
    AUTH_API + `auth/active-logins?${filter}page=${page}&size=${size}`,
    customConfig
  );
};

const getUserList = (roleId, search, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (+search.course) {
    filter += `course=${search.course}&`;
  }
  if (+search.yearofstudy) {
    filter += `yearofstudy=${search.yearofstudy}&`;
  }
  if (+search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }
  let params = `?${filter.slice(0, -1)}`;

  return axios.get(
    USER_API + `user/${roleId}/list${params}`,
    customConfig
  );
};

const getUserPermissions = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `permissions/user`,
    customConfig
  );
};
const promoteStudents = (yearOfStudy, course, data, token) => {
  const body = {
    [course]: data,
  };
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.post(
    USER_API + `user/promote/${yearOfStudy}`,
    body,
    customConfig
  );
};

const setPermissions = (id, data, token, roleExist) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  if (id && roleExist) {
    return axios.put(
      USER_API + `permissions/${id}`,
      data,
      customConfig
    );
  }
  if (!roleExist || !id) {
    return axios.post(
      USER_API + `permissions`,
      data,
      customConfig
    );
  }
};

const deleteRole = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `permissions/${id}`,
    customConfig
  );
};

const roleExist = (role, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `permissions/${role}/exist`,
    customConfig
  );
};

const getNotification = (id, search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }
  if (search.year) {
    filter += `year=${search.year}&`;
  }
  if (search.pending) {
    filter += `pending=${search.pending}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;
  if (!id)
    return axios.get(
      USER_API + `notification/${params}`,
      customConfig
    );

  return axios.get(
    USER_API + `notification/${id}${params}`,
    customConfig
  );
};

const getUserNotification = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `notification/user?page=1&size=40`,
    customConfig
  );
};

const getUnreadNotificationCount = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `notification/user/unread/count?page=1&size=40`,
    customConfig
  );
};

const getnotificationUnread = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `notification/user/unread`,
    customConfig
  );
};

const getAwards = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.id) {
    filter += `id=${search.id}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;

  return axios.get(
    USER_API + `awards${params}`,
    customConfig
  );
};

const setAward = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  for (let i = 0; i < data?.files?.length; i++) {
    formData.append("files", data.files[i], data.files[i].name);
  }
  formData.append(
    "awardinfo",
    JSON.stringify({
      title: data.title,
      description: data.awardinfo,
      date: data.date,
    })
  );

  if (data.id)
    return axios.put(
      USER_API + `awards/` + data.id,
      formData,
      customConfig
    );

  return axios.post(
    USER_API + "awards",
    formData,
    customConfig
  );
};


const deleteAward = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `awards/${id}`,
    customConfig
  );
};

const notificationRead = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  if (id)
    return axios.patch(
      USER_API + `notification/user/read/${id}`,
      {},
      customConfig
    );
  return axios.patch(
    USER_API + `notification/user/read/all`,
    {},
    customConfig
  );
};
const createNotification = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `notification/${data.id}`,
      data,
      customConfig
    );
  return axios.post(
    USER_API + `notification`,
    data,
    customConfig
  );
};

const setNotificationRemarks = (id, course, remarks, approval, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    USER_API + "notification/remarks/" + id,
    {
      id,
      course,
      remarks,
      approval,
    },
    customConfig
  );
};

const deleteNotification = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `notification/${id}`,
    customConfig
  );
};

const getNotice = (id, search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }
  if (search.year) {
    filter += `year=${search.year}&`;
  }
  if (search.subject) {
    filter += `subject=${search.subject}&`;
  }
  if (search.pending) {
    filter += `pending=${search.pending}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;
  if (!id)
    return axios.get(
      USER_API + `notice${params}`,
      customConfig
    );

  return axios.get(
    USER_API + `notice/${id}${params}`,
    customConfig
  );
};

const createNotice = (isupdating, data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  const noticeData = {
    id: data.id,
    course: data.course,
    subcourse: data.subcourse,
    title: data.title,
    description: data.description,
    skipapproval: data.skipapproval,
    yearofstudy: data.yearofstudy,
  };
  formData.append("file", data.file, data.filename);
  formData.append("noticeboardDto", JSON.stringify(noticeData));

  if (isupdating && data.id)
    return axios.put(
      USER_API + `notice`,
      formData,
      customConfig
    );
  return axios.post(
    USER_API + `notice`,
    formData,
    customConfig
  );
};

const setNoticeRemarks = (id, course, remarks, approval, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    USER_API + "notice/remarks/" + id,
    {
      id,
      course,
      remarks,
      approval,
    },
    customConfig
  );
};

const deleteNotice = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `notice/${id}`,
    customConfig
  );
};

const getReviewFlow = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  // if(!id) return axios.get(USER_API + `notification?page=1&size=40`, customConfig);

  return axios.get(
    USER_API + `reviewflow/getFlows?page=1&size=100`,
    customConfig
  );
};

const getPendingOnMe = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `reviewflow/pending-on-me`,
    customConfig
  );
};

const getReviewFlowByType = (type, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  // if(!id) return axios.get(USER_API + `notification?page=1&size=40`, customConfig);

  return axios.get(
    USER_API + `reviewflow/${type}?courseId`,
    customConfig
  );
};

const setReviewFlow = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  //   let filter;
  //   if(data?.course){
  // filter=`?courseId=${data.course}`
  //   }
  if (data.id)
    return axios.put(
      USER_API + `reviewflow`,
      data,
      customConfig
    );
  delete data?.id
  return axios.post(
    USER_API + `reviewflow`,
    data,
    customConfig
  );
};

const getPayment = (admission, search, page = 1, size = 5, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.feetype) {
    filter += `feetype=${search.feetype}&`;
  }
  if (search.status) {
    filter += `status=${search.status}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;
  return axios.get(
    USER_API + `payments/${admission}${params}`,
    customConfig
  );
};
const paymentsReport = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `payments/report`,
    customConfig
  );
};

const paymentDueExist = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `payments/dues/exist`,
    customConfig
  );
};

const getPaymentWorkshopByAdmission = (admission, workshop, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let filter = "?";
  if (admission) {
    filter += `admissionnumber=${admission}&`;
  }

  filter += `workshop=${workshop}`;

  return axios.get(
    USER_API + `payments/workshop${filter}`,
    customConfig
  );
};

const paymentDuesByCourse = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `payments/duesbycourse`,
    customConfig
  );
};

const getHeadWiseTotal = (search) => {
  const param = Object.keys(search).map((i) => search[i] ? `${i}=${search[i]}` : '').filter((a) => !!a).join('&');
  return axios.get(USER_API + `fee/headWiseTotals?${param}`, getApiHeader());
}

const getPayments = (search, page, size, token, refund_only, only_mqPayments) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.status) {
    filter += `status=${search.status}&`;
  }
  if (search.feetype) {
    filter += `feetype=${search.feetype}&`;
  }
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }
  if (search.year) {
    filter += `yearofstudy=${search.year}&`;
  }
  if (search.admissionnumber) {
    filter += `admissionnumber=${search.admissionnumber}&`;
  }
  if (search.invoicenumber) {
    filter += `invoicenumber=${search.invoicenumber}&`;
  }
  if (refund_only) {
    filter += `status=success&`;
  }
  if (only_mqPayments) {
    filter += `status=Not Received&`;
    filter += `only-mq-payments=true&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;

  return axios.get(
    USER_API + `payments${params}`,
    customConfig
  );
};
const getPaymentsSummary = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.status) {
    filter += `status=${search.status}&`;
  }
  if (search.feetype) {
    filter += `feetype=${search.feetype}&`;
  }
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }

  if (search.admissionnumber) {
    filter += `admissionNumber=${search.admissionnumber}&`;
    filter += search.fromDate ? `fromDateTime=${search.fromDate}&` : '';
    filter += search.toDate ? `toDateTime=${search.toDate}&` : '';
  }
  if (search.year) {
    filter += `yearOfStudy=${search.year}&`;
  }

  if (search.invoicenumber) {
    filter += `invoicenumber=${search.invoicenumber}&`;
  }
  let params = `?${filter}page=0&size=${size}`;

  return axios.get(
    USER_API + `payments/summary${params}`,
    customConfig
  );
};
const downloadPayment = (token) => {
  const customConfig = {
    responseType: 'blob',
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `payments/download`,
    customConfig
  );
};

const newPayment = (ids, amount, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  };
  const user = AuthService.currentUser();

  let params = "id=" + ids.join(",");

  return axios.put(
    USER_API + `payments/new?${params}`,
    {
      amount: amount,
      admissionnumber: user.userid
    },
    customConfig
  );
};

const newPaymentAxis = (ids, amount, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      Accept: "application/json",
    },
  };

  let params = "id=" + ids.join(",");

  return axios.put(
    USER_API + `payments/new/axis?${params}`,
    { amount },
    customConfig
  );
};

const paymentValidate = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.post(
    USER_API + `payments/bd/validate`,
    data,
    customConfig
  );
};

const paymentValidateAxis = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.post(
    USER_API + `payments/validate`,
    data,
    customConfig
  );
};

const setPayment = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `payments`,
      data,
      customConfig
    );

  return axios.post(
    USER_API + `payments`,
    data,
    customConfig
  );
};

const bulkPayment = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id) return axios.put(USER_API + `payments`, data, customConfig);

  return axios.post(
    USER_API + `payments/bulk`,

    data,
    customConfig
  );
};

const editMQPayment = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    USER_API + `payments`,

    data,
    customConfig
  );
};

const viewReviewerCount = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `student/admissionscountbyreviewer`,
    customConfig
  );
};

const getStudentCount = (course, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `student/count/${course}`,
    customConfig
  );
};

const getstudentInfo = (token, admissionNumber) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `student/${admissionNumber}`,
    customConfig
  );
};

const getPaymentDetails = (userId, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `payments/${userId}`,
    customConfig
  );
};

const getWorkshops = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.yearofstudy) {
    filter += `yearofstudy=${search.yearofstudy}&`;
  }
  if (search.subcourse) {
    filter += `subcourse=${search.subcourse}&`;
  }
  if (search.admissionnumber) {
    filter += `admissionnumber=${search.admissionnumber}&`;
  }
  if (search.rollnumber) {
    filter += `rollnumber=${search.rollnumber}&`;
  }
  if (search.name) {
    filter += `name=${search.name}&`;
  }
  if (search.year) {
    filter += `yearofstudy=${search.year}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;

  return axios.get(
    USER_API + `workshops${params}`,
    customConfig
  );
};

const setWorkshop = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `workshops/` + data.id,
      data,
      customConfig
    );

  return axios.post(
    USER_API + `workshops`,
    data,
    customConfig
  );
};

const deleteWorkshop = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `workshops/${id}`,
    customConfig
  );
};

const setCompany = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `settings`,
      data,
      customConfig
    );

  return axios.post(
    USER_API + `settings`,
    data,
    customConfig
  );
};

const getCompany = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `settings?page=1&size=40`,
    customConfig
  );
};

const setHelp = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  for (let i = 0; i < data.files.length; i++) {
    formData.append("files", data.files[i], data.files[i].name);
  }
  formData.append(
    "helpinfo",
    JSON.stringify({
      title: data.title,
      description: data.description,
      helpTypes: data.issues,
    })
  );

  if (data.id)
    return axios.put(
      USER_API + `help`,
      formData,
      customConfig
    );

  return axios.post(
    USER_API + `help`,
    formData,
    customConfig
  );
};

const getHelp = (page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `help?page=${page}&size=${size}`,
    customConfig
  );
};

const deleteHelp = (helpId, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.delete(
    USER_API + `help/${helpId}`,
    customConfig
  );
};

const getFee = (token, data = {}) => {

  let filter = "";
  if (data.pending) {
    filter += `pending=${data.pending}&`;
  }
  let params = `?${filter}page=${1}&size=${500}`;
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(
    USER_API + `fee${params}`,
    customConfig
  );
};

const getFeeTypes = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(USER_API + `fee/feetype`, customConfig);
};

const setFee = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(USER_API + `fee`, data, customConfig);

  return axios.post(USER_API + `fee`, data, customConfig);
};

const getHostelFee = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application",
      Authorization: "Bearer " + token,
    }
  }
  return axios.get(USER_API + `fee/getHostelFee?courseId=${id}`, customConfig);
}

const getAllHostelFee = (pending, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application",
      Authorization: "Bearer " + token,
    }
  }
  let filter = '';
  if (pending) {
    filter += `?pending=${pending}`
  }
  return axios.get(USER_API + `fee/getAllHostelFee${filter}`, customConfig);
}

const setHostelFee = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(USER_API + `fee/updateHostelFee`, data, customConfig);

  return axios.post(USER_API + `fee/addHostelFee`, data, customConfig);
};

const deleteHostelFee = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(USER_API + `fee/deleteHostelFee/${id}`, customConfig);
}

const setHostelFeeApproval = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    USER_API + "fee/hostelFee/remarks",
    data,
    customConfig
  );
};

const setFeeApproval = (id, course, remarks, approval, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.put(
    USER_API + "fee/remarks/" + id,
    {
      id,
      course,
      remarks,
      approval,
    },
    customConfig
  );
};

const setFeeType = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `fee/feetype`,
      data,
      customConfig
    );

  return axios.post(
    USER_API + `fee/feetype`,
    data,
    customConfig
  );
};

const editFeeType = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  if (data.id)
    return axios.put(
      USER_API + `fee/feetype/${data.id}`,
      data,
      customConfig
    );

  return axios.post(
    USER_API + `fee/feetype/${data.id}`,
    data,
    customConfig
  );
};

const getFeeByAdmission = (admissionnumber, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  return axios.get(USER_API + `fee/getFeeByAdmission?admissionNumber=${admissionnumber}`, customConfig);
};

const importFee = (data, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  formData.append("file", data.file, data.file.name);
  formData.append("course", data.course);
  formData.append("subcourse", data.subcourse);
  formData.append("yearofstudy", data.yearofstudy);

  return axios.post(
    USER_API + `fee/import?batchYear=${data.batchYear}`,
    formData,
    customConfig
  );
};

const deleteFee = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(USER_API + `fee/${id}`, customConfig);
};

const deleteFeeType = (id, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.delete(
    USER_API + `fee/feetype/${id}`,
    customConfig
  );
};

const verifyMobileNumber = (number, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `user/otp/generate/${number}`,
    customConfig
  );
};

const validateOtp = (number, otp, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `user/${number}/otp/validate/${otp}`,
    customConfig
  );
};

const getLogs = (search, page, size, token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  let filter = "";
  if (search.course) {
    filter += `course=${search.course}&`;
  }
  if (search.subcourse) {
    filter += `status=${search.subcourse}&`;
  }
  if (search.year) {
    filter += `year=${search.year}&`;
  }
  if (search.rollnumber) {
    filter += `rollnumber=${search.rollnumber}&`;
  }
  if (search.admissionnumber) {
    filter += `admissionnumber=${search.admissionnumber}&`;
  }
  if (search.type) {
    filter += `type=${search.type}&`;
  }
  let params = `?${filter}page=${page}&size=${size}`;

  return axios.get(USER_API + `logs${params}`, customConfig);
};

const getMarksPerformance = (token) => {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };
  return axios.get(
    USER_API + `marks/performance`,
    customConfig
  );
};

const examToppers = () => {
  return axios.get(`${USER_API}marks/toppers`, getApiHeader());
};

const AuthService = {
  admission,
  createUser,
  deleteAdmission,
  login,
  logout,
  currentUser,
  getUser,
  getUserByAdmission,
  googleAuth,
  validateGoogleAuth,
  promoteStudents,
  getstudentInfo,
  uploadUserProfilePic,

  createProfessor,
  getProfessor,

  isUserLoggedIn,
  resetPassword,
  changePassword,
  getRole,
  getRoles,
  getModules,
  setPermissions,
  getUserPermissions,
  getUserList,
  deleteRole,
  roleExist,

  getAwards,
  setAward,
  deleteAward,

  createNotification,
  getNotification,
  getUserNotification,
  getnotificationUnread,
  getUnreadNotificationCount,
  notificationRead,
  setNotificationRemarks,
  deleteNotification,
  downloadPayment,
  createNotice,
  getNotice,
  setNoticeRemarks,
  deleteNotice,
  getActiveUsers,
  getReviewFlow,
  setReviewFlow,
  getReviewFlowByType,
  getPendingOnMe,

  getPayment,
  getPaymentDetails,
  setPayment,
  getPayments,
  getPaymentsSummary,
  getHeadWiseTotal,
  newPayment,
  newPaymentAxis,
  paymentValidate,
  paymentValidateAxis,
  paymentsReport,
  paymentDueExist,
  paymentDuesByCourse,
  bulkPayment,
  editMQPayment,
  getPaymentWorkshopByAdmission,

  getWorkshops,
  setWorkshop,
  deleteWorkshop,

  setCompany,
  getCompany,

  setHelp,
  getHelp,
  deleteHelp,
  getHostelFee,
  getAllHostelFee,
  setHostelFee,
  setHostelFeeApproval,
  deleteHostelFee,

  getFee,
  getFeeTypes,
  setFee,
  setFeeType,
  getFeeByAdmission,
  setFeeApproval,
  editFeeType,
  deleteFeeType,
  importFee,
  deleteFee,

  viewReviewerCount,
  getStudentCount,
  verifyMobileNumber,
  validateOtp,

  getLogs,
  getMarksPerformance,
  examToppers,
};

export default AuthService;
