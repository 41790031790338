export const declarationFormCheck = /\b(Approved|Rejected|Submitted|Resubmitted)\b/i;
export const isApproved = /Approved/i;
export const isStudent = /student/i;
export const isLibrarian = /Librarian/i;
export const isProfessor = /professor/i;
export const isItAdmin = /it\s?admin/i;
export const isFinance = /finance/i;
export const isSuperAdmin = /super\s?admin/i;
export const isPrincipal = /principal/i;
export const isSlide = /slide/i;
export const nonEditDocs = /\b(PROFILEPIC|material|syllabus)\b/i;
// export const commonRoutes = /\b(changePassword|forgotPassword|NotFound|InternalServer|newprofile|needHelp|faqs)\b/i;
export const commonRoutes = /\b(changePassword|forgotPassword|NotFound|InternalServer|newprofile|needHelp|faqs)\b/i;
export const adminRoutes = /\b(approvalFlow|setting|syllabus|logs|advancedsearch|fine)\b/i;
export const dataDisable = /\b(Approved|Submitted|Resubmitted|InProgress)\b/i;
export const  disableRoutesToMBBSCStd=/\b(Payments|Transactions)\b/i;
export const disableRoutesToStudent = /\b(Attendance|Noticeboard|Dashboard|FAQs|Materials|Noticeboard|Notifications|Workshops|Syllabus|Marks|Awards|MQpayments|ActiveUsers|AdmissionReport|Course|Subjects|Documents|Fee|Academic|Non Academic)\b/i;
export const dashboardSubList = /\b(Dashboard|OVERALL\s?REPORT|PAYMENTS\s?HISTORY|PERFORMANCE|EXAM\s?TOPPERS|UNIVERSITY\s?REPORT|STUDENTS\s?REPORT|ADMISSION\s?REPORT)\b/i;