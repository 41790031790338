export const universalMenu = {
  Dashboard: { id: "Dashboard", label: "Dashboard", to: "/dashboard", },
  Registrations: { id: "Registrations", label: "Registrations", to: "/registrations", },
  Admissions: { id: "Students", label: "Admissions", to: "/admissions", },
  Slide: { id: "Slide", label: "Slide", to: "/slide", },
  Students: { id: "Student", label: "Students", to: "/students", },
  Professors: { id: "Professors", label: "Professors", to: "/professors", },
  Course: { id: "Course", label: "Courses", to: "/course", },
  Subjects: { id: "Subjects", label: "Subjects", to: "/subjects", },
  Documents: { id: "Documents", label: "Documents", to: "/documents", },
  Noticeboard: { id: "Noticeboard", label: "Noticeboard", to: "/noticeboard", },
  Notifications: { id: "Notifications", label: "Notifications", to: "/notifications", },
  Attendance: { id: "Attendance", label: "Attendance", to: "/attendance", },
  Materials: { id: "Materials", label: "Materials", to: "/materials", },
  Fee: { id: "fee", label: "Fee", to: "/fee", },
  HostelFee: { id: "HostelFee", label: "Hostel Fee", to: "/hostelFee", },
  Feetype: { id: 'feeType', label: 'Fee Type', to: '/feeType' },
  Roles: { id: "Roles", label: "Roles & Permissions", to: "/roles", },
  Users: { id: "Users", label: "Users", to: "/users", },
  Syllabus: { id: "Syllabus", label: "Syllabus", to: "/syllabus", },
  FAQs: { id: "FAQs", label: "FAQs", to: "/faqs", },
  Marks: { id: "Marks", label: "Marks", to: "/marks", },
  AdvancedSearch: { id: "AdvancedSearch", label: "Student 360", to: "/advancedsearch", visible: ["admin"], },
  Payments: { id: "Payments", label: "Payments", to: "/payments", },
  PaymentStatus: { id: "PaymentStatus", label: "Payment", to: "/payment-status", },
  PaymentsSummary: { id: "PaymentsSummary", label: "Payments Summary", to: "/paymentsSummary", },
  Workshops: { id: "workshops", label: "Workshops", to: "/workshops", },
  'MQ Payments': { id: "MQpayments", label: "MQPayments", to: "/MQpayments", },
  Refund: { id: "Refund", label: "Refund", to: "/refund", },
  "Library Dashboard": { id: "dashboard", label: "Library Dashboard", to: "/libraryDashboard", },
  Books: { id: "books", label: "Catalogue", to: "/book", },
  Journals: { id: "journals", label: "Journals", to: "/journal", },
  "Assign Book": { id: "Assign Book", label: "Assign Books", to: "/assignBooks", },
  "Assign Journals": { id: "Assign Journals", label: "Assign Journal", to: "/assignJournals", },
  Issues: {
    id: "issues", label: "Circulations", to: "/circulations",
    content: [
      { id: "Issues", label: "Issued Books", to: "/issueBooks", },
      // { id: "ReturnedBooks", label: "Returned Books", to: "/returnBooks", },
      { id: "Damaged book", label: "Damaged Books", to: "/damageBooks", },
      { id: "Issued Journals", label: "Issued Journals", to: "/issueJournals", },
      // { id: "ReturnedJournals", label: "Returned Journals", to: "/returnJournals", },
      { id: "Damaged Journals", label: "Damaged Journals", to: "/damageJournals", },
    ]
  },
  "Admission Report": { id: "Admission Report", label: "Admission Report", to: "/Admission", },
  'Requested Books': { id: "Requested Books", label: "Requested Books", to: "/requestBooks", },
  'Requested Journals': { id: "Requested Journals", label: "Requested Journals", to: "/requestJournals", },
  "Request TransferBooks": { id: "Request TransferBooks", label: "Request Transfer Books", to: "/requestTransferBooks", },
  "Assign TransferBooks": { id: "Assign TransferBooks", label: "Assign Transfer Books", to: "/issueTransferBooks", },
  "Recommended Books": { id: "Recommended Books", label: 'Recommend Books', to: '/recommendBook'}
};

export const adminMenu = [
  { id: "ApprovalFlow", label: "Approval Flow", to: "/approvalFlow", },
  { id: "Roles", label: "Roles & Permissions", to: "/roles" },
  { id: "User", label: "Users", to: "/users" },
  {
    id: "Fee", label: "Fee", to: "/fee",
    content: [
      { id: "Fee1", label: "Fee", to: "/fee", },
      { id: "Fee2", label: "Hostel Fee", to: "/hostelFee", },
      { id: "Fee3", label: "Fee Type", to: "/feeType", },
    ]
  },
  { id: "workshops", label: "Workshops", to: "/workshops", },
  { id: "AdvancedSearch", label: "Student 360", to: "/advancedsearch" },
  { id: "ActiveUsers", label: "ActiveUsers", to: "/activeusers" },
  { id: "Marks", label: "Marks", to: "/marks" },
  { id: "Logs", label: "Logs", to: "/logs", },
  {
    id: "Transactions", label: "Accounts", to: "/payments",
    content: [
      { id: "payments", label: "Transactions", to: "/payments", },
      { id: "refund", label: "Refund", to: "/refund", },
      { id: "PaymentsSummary", label: "Ledger", to: "/paymentsSummary", },
      { id: "headLedger", label: "Head Ledger", to: "/headLedger", },
      { id: "MQpayments", label: "MQPayments", to: "/MQpayments", },
    ]
  },
  { id: 'Downloads', label: 'Downloads', to: '/downloads', },
  { id: "Setting", label: "Settings", to: "/setting", },
  { id: "FAQs", label: "FAQs", to: "/faqs", },
];

export const universityAdminMenu = [
  {
    id: "Dashboard", label: "Dashboard", to: "/dashboard",
    content: [
      { id: "Main dashboard", label: "Main Dashboard", to: "/dashboard", },
      { id: "Librarian dashboard", label: "Librarian Dashboard", to: "/libraryDashboard", },
      { id: "Admission dashboard", label: "Admission Report", to: "/Admission", },
    ]
  },
  { id: "Registrations", label: "Registrations", to: "/registrations", },
  { id: "Students", label: "Admissions", to: "/admissions", },
  { id: "Slide", label: "Slide", to: "/slide", },
  { id: "ApprovedStudents", label: "Students", to: "/students", },
  { id: "Professors", label: "Professors", to: "/professors", },
  { id: "Users", label: "Courses", to: "/course", },
  { id: "Subjects", label: "Subjects", to: "/subjects", },
  { id: "Documents", label: "Documents", to: "/documents", },
  { id: "Noticeboard", label: "Noticeboard", to: "/noticeboard", },
  { id: "Notifications", label: "Notifications", to: "/notifications", },
  { id: "Attendance", label: "Attendance", to: "/attendance", },
  { id: "Materials", label: "Materials", to: "/materials", },
  { id: "Syllabus", label: "Syllabus", to: "/syllabus", },
  { id: "catalogue", label: "Catalogue", to: "/book", },
  {
    id: "Circulations", label: "Circulations", to: "/circulations",
    content: [
      { id: "Issues", label: "Issued Books", to: "/issueBooks", },
      // { id: "Returned Books", label: "Returned Books", to: "/returnBooks", },
      { id: "Damaged Books", label: "Damaged Books", to: "/damageBooks", },
      { id: "Issues Journals", label: "Issued Journals", to: "/issueJournals", },
      // { id: "Returned Journals", label: "Returned Journals", to: "/returnJournals", },
      { id: "Damaged Journals", label: "Damaged Journals", to: "/damageJournals", },
    ]
  },
  { id: "journals", label: "Journals", to: "/journal", },
  { id: "assign Book", label: "Assign Books", to: "/assignBooks", },
  { id: "assignJournal", label: "Assign Journal", to: "/assignJournals", },
  { id: "Request Books", label: "Requested Books", to: "/requestBooks", },
  { id: "Request Journals", label: "Requested Journals", to: "/requestJournals", },
  { id: "Request Transfer Books", label: "Request Transfer Books", to: "/requestTransferBooks", },
  { id: "Assign Transfer Books", label: "Assign Transfer Books", to: "/issueTransferBooks", },
  { id: "Recommended Books", label: 'Recommend Books', to: '/recommendBook'}
];

export const universityStudentMenu = {
  Dashboard: { id: "Dashboard", label: "Dashboard", to: "/dashboard", },
  Admissions: { id: "Students", label: "Admissions", to: "/admissions", },
  Course: { id: "Course", label: "Courses", to: "/course", },
  Subjects: { id: "Subjects", label: "Subjects", to: "/subjects", },
  Documents: { id: "Documents", label: "Documents", to: "/documents", },
  Noticeboard: { id: "Noticeboard", label: "Noticeboard", to: "/noticeboard", },
  Notifications: { id: "Notifications", label: "Notifications", to: "/notifications", },
  Attendance: { id: "Attendance", label: "Attendance", to: "/attendance", },
  Materials: { id: "Materials", label: "Materials", to: "/materials", },
  Syllabus: { id: "Syllabus", label: "Syllabus", to: "/syllabus", },
  Workshops: { id: "workshops", label: "Workshops", to: "/workshops", },
  Marks: { id: "Marks", label: "Marks", to: "/marks", },
  Awards: { id: "Awards", label: "Awards", to: "/awards", },
  Transactions: { id: "Transactions", label: "Transactions", to: "/transactions", },
  FAQs: { id: "FAQs", label: "FAQs", to: "/faqs", },
  "Library Dashboard": { id: "dashboard", label: "Library Dashboard", to: "/libraryDashboard", },
  Books: { id: "books", label: "Catalogue", to: "/book", },
  Journals: { id: "journals", icon: "journals", label: "Journals", to: "/journal", },
  'Requested Books': { id: "Requested Books", label: "Requested Books", to: "/requestBooks", },
  'Issues': { id: "issues", label: "Issued Books", to: "/issueBooks", },
  // 'Return Books': { id: "Return Books", label: "Return Books", to: "/returnBooks", },
  "Assign Books": { id: "assign Book", label: "Assign Books", to: "/assignBooks", },
  'Requested Journals': { id: "Requested Journals", label: "Requested Journals", to: "/requestJournals", },
  "Recommended Books": { id: "Recommended Books", label: 'Recommend Books', to: '/recommendBook'},
  'Issued Journals': { id: "issued Journals", label: "Issued Journals", to: "/issueJournals", },
  // 'Return Journals': { id: "Return Journals", label: "Return Journals", to: "/returnJournals", },
  "Assign Journals": { id: "assign Journals", label: "Assign Journal", to: "/assignJournals", },
};
