import React, { Component } from "react";
import { Link } from "react-router-dom";
import { validateForm } from "../../App";

import AuthService from "./../../services/AuthService";
import background from "../../assets/images/gallery/login_background.png";
import logo from "../../assets/images/asramlogo.png";
import Swal from "sweetalert2";

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			userName: null,
			errors: {
				userName: "",
				formOverall: "",
			},
		};
	}

	handleChange = (e) => {
		e.preventDefault();

		const { name, value } = e.target;
		let errors = this.state.errors;

		errors.formOverall = "";

		switch (name) {
			case "userName":
				errors.userName =
					value === "" ? "Please enter admission/employee number." : "";
				break;
			default:
				break;
		}
		this.setState({ ...this.state, [name]: value });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		let { errors } = this.state;
		const { userName } = this.state;
		if (userName == null) {
			errors.userName = "Please enter admission number";
			this.setState({ errors, formOverall: "" });
		} else {
			if (validateForm(this.state.errors)) {
				this.setState({
					loading: true,
				});

				try {
					await AuthService.resetPassword(userName);
					Swal.fire({
						icon: "success",
						title: `Password reset is successfull`,
						text: "Please check your mail for new password",
						// showConfirmButton: false,
						// timer: 2000
					});
					
					this.setState({
						loading: false,
					});
					window.location.replace("/#/login");
				} catch (error) {
					console.log(error);
					Swal.fire({
						icon: "error",
						title: "Reset failed",
						text: error?.response?.data?.message || error.toString(),
					});
					this.setState({
						loading: false,
					});
				}
			} else {
				console.error("Invalid form data.");
			}
		}
	};

	render() {
		const { errors, loading } = this.state;
		return (
			<div
				className="auth option2"
				style={{
					backgroundImage: `url(${background})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: 'right',
				}}
			>
				<div className="auth_left">
					<div className="">
						<form className="card-body" onSubmit={this.handleSubmit} noValidate>
							<div className="card-body" style={{ width: "300px" }}>
								<div className="text-center pb-20">
									<Link className="header-brand" to="/">
										<img src={logo} alt='logo'/>
									</Link>
									<div className="card-title mt-30"></div>
								</div>
								<div className="form-group">
									<div className="input-group">
										<input
											type="email"
											className="form-control input-lg rounded-0"
											style={{
												borderTop: "none",
												borderLeft: "none",
												borderRight: "none",
												borderColor: "#acacac",
											}}
											aria-describedby="emailHelp"
											placeholder="Admission/employee number"
											name="userName"
											onChange={this.handleChange}
											noValidate
										></input>
										<span
											className="input-group-text"
											style={{
												borderTop: "none",
												borderLeft: "none",
												borderRight: "none",
												borderColor: "#acacac",
											}}
										>
											<i className="fa fa-user mr-1"></i>
										</span>
									</div>
									{errors.userName.length > 0 && (
										<div>
											<span className="text-warning">{errors.userName}</span>
										</div>
									)}
								</div>

								<div
									className="col-md-12 row pt-3"
									style={{ padding: "0px", margin: "0px" }}
								>
									<div
										className="col-md-6 float-left text-left"
										style={{ alignItems: "center" }}
									>
										<label className="pt-1 font-19">
											<Link to="/" className="font-weight-bold small">
												Back to login?
											</Link>
										</label>
									</div>
									<div className="col-md-6">
										<button
											className="btn btn-primary btn-block"
											disabled={loading}
											type="submit"
										>
											{loading && (
												<span className="spinner-border spinner-border-sm"></span>
											)}
											<span>Submit</span>
										</button>
										{errors.formOverall.length > 0 && (
											<span className="text-warning">{errors.formOverall}</span>
										)}
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}
