import axios from 'axios'

const USER_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_USER_SERVICE_PORT;
const DOC_API = process.env.REACT_APP_API_ENDPOINT + process.env.REACT_APP_DOC_SERVICE_PORT;

// const updateLocalStudentProfile = (student) => {
//     const profile = {
//         ...JSON.parse(localStorage.getItem('user')),
//         ...student
//     };
//     localStorage.setItem('user', JSON.stringify(profile));
// }

const updateBasicInfo = (
    admissionnumber, { course, subcourse, category, firstname, middlename, lastname,
        email, gender, mothertongue, dateofjoin, identification1,
        identification2, nationality, religion, socialstatus, community,
        mobilenumber, dateofbirth, parentName, parentMobilenumber,
        addressline1, addressline2, country, state, city, otpValidated, parentOtpValidated, pgClinic, batchYear }, fatherEmail,
    fatherOccupation,
    aadharnumber,
    fatherAadharnumber,
    pannumber,
    fatherPan,
    otherParticulars,
    hostelaccommondation,
    token, firstUser
) => {
    const yearofstudy = 1;
    const role = 'Student';
    const position = 'Student';

    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (firstUser) {
        return axios.post(USER_API + "student", {
            admissionnumber, yearofstudy, role, course, subcourse, category, firstname, middlename, lastname,
            email, gender, mobilenumber, position, mothertongue, dateofbirth, batchYear,
            dateofjoin, identification1, identification2, nationality, religion, socialstatus,
            community, parentName, parentMobilenumber, addressline1, addressline2, country, state, city, parentOtpValidated, otpValidated, pgClinic
        }, customConfig);
    } else {
        return axios.put(USER_API + "student/" + admissionnumber, {
            admissionnumber,
            yearofstudy, role, course, subcourse, category, firstname, middlename, lastname,
            email, gender, mobilenumber, position, mothertongue, dateofbirth, batchYear,
            dateofjoin, identification1, identification2, nationality, religion, socialstatus,
            community, parentName, parentMobilenumber, addressline1, addressline2, country, state, city,
            fatherEmail,
            fatherOccupation,
            aadharnumber,
            fatherAadharnumber,
            pannumber,
            fatherPan,
            otherParticulars,
            hostelaccommondation,
            parentOtpValidated, otpValidated, pgClinic
        }, customConfig);
    }
}

const updateStudentAdditionalInfo = (
    user,
    fatherEmail,
    fatherOccupation,
    aadharnumber,
    fatherAadharnumber,
    pannumber,
    fatherPan,
    otherParticulars,
    hostelaccommondation,
    token
) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const {
        admissionnumber, yearofstudy, role, course, subcourse, category, firstname, middlename, lastname,
        email, gender, mobilenumber, position, mothertongue, dateofbirth,
        dateofjoin, identification1, identification2, nationality, religion, socialstatus, pgClinic,
        community, parentName, parentMobilenumber, addressline1, addressline2, country, state, city, otpValidated, parentOtpValidated
    } = user;
    return axios.put(USER_API + "student/" + admissionnumber, {
        admissionnumber,
        yearofstudy, role, course, subcourse, category, firstname, middlename, lastname,
        email, gender, mobilenumber, position, mothertongue, dateofbirth, batchYear: 2024,
        dateofjoin, identification1, identification2, nationality, religion, socialstatus,
        community, parentName, parentMobilenumber, addressline1, addressline2, country, state, city,
        fatherEmail,
        fatherOccupation,
        aadharnumber,
        fatherAadharnumber,
        pannumber,
        fatherPan,
        otherParticulars,
        hostelaccommondation,
        otpValidated, parentOtpValidated, pgClinic,
    }, customConfig);
}

const studentByAdmission = (num, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.get(USER_API + "student/" + num, customConfig);
}

const studentReSubmit = (admissionNumber, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.put(USER_API + "student/submit/" + admissionNumber, {}, customConfig);
}

const updateAcadInfo = (
    id,
    admissionnumber,
    physicsObtainedMarks,
    chemistryObtainedMarks,
    biologyObtainedMarks,
    vocationalObtainedMarks,
    totalMaxMarks,

    intermediateObtainedMarks,
    intermediateMaxMarks,
    intermediateGroupObtainedMarks,
    intermediateGroupMaxMarks,
    englishObtainedMarks,
    englishMaxMarks,

    collegeAddress,
    neetHallTicketNumber,
    neetRank,
    neetStateRank,
    neetObtainedMarks,
    neetMaxMarks,

    minQualifyingMarks,
    entranceObtainedMarks,
    selected,
    mbbsJoiningDate,
    mbbsPassingDate,
    universityAndState,

    mbbsCollegeAddress,
    firstMbbsMaxMarks,
    firstMbbsObtainedMarks,
    secondMbbsMaxMarks,
    secondMbbsObtainedMarks,
    finalMbbsPart1ObtainedMarks,
    finalMbbsPart1MaxMarks,
    finalMbbsPart2ObtainedMarks,
    finalMbbsPart2MaxMarks,

    pgNeetHallTicketNumber,
    pgNeetRank,
    pgNeetStateRank,
    pgNeetObtainedMarks,
    pgNeetMaxMarks,
    internshipStartDate,
    internshipEndDate,
    internshipDuration,
    internshipCollegeName,
    internshipCollegeAddress,

    internshipHospitalName,
    internshipHospitalAddress,
    medicalCouncilName,
    medicalCouncilRegNumber,
    medicalCouncilRgDate,
    token,
    found
) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (!found) {
        return axios.post(USER_API + "student/academics", {
            admissionnumber,
            physicsObtainedMarks,
            chemistryObtainedMarks,
            biologyObtainedMarks,
            vocationalObtainedMarks,
            totalMaxMarks,

            intermediateObtainedMarks,
            intermediateMaxMarks,
            intermediateGroupObtainedMarks,
            intermediateGroupMaxMarks,
            englishObtainedMarks,
            englishMaxMarks,

            collegeAddress,
            neetHallTicketNumber,
            neetRank,
            neetStateRank,
            neetObtainedMarks,
            neetMaxMarks,

            minQualifyingMarks,
            entranceObtainedMarks,
            selected,
            mbbsJoiningDate,
            mbbsPassingDate,
            universityAndState,

            mbbsCollegeAddress,
            firstMbbsMaxMarks,
            firstMbbsObtainedMarks,
            secondMbbsMaxMarks,
            secondMbbsObtainedMarks,
            finalMbbsPart1ObtainedMarks,
            finalMbbsPart1MaxMarks,
            finalMbbsPart2ObtainedMarks,
            finalMbbsPart2MaxMarks,

            pgNeetHallTicketNumber,
            pgNeetRank,
            pgNeetStateRank,
            pgNeetObtainedMarks,
            pgNeetMaxMarks,
            internshipStartDate,
            internshipEndDate,
            internshipDuration,
            internshipCollegeName,
            internshipCollegeAddress,

            internshipHospitalName,
            internshipHospitalAddress,
            medicalCouncilName,
            medicalCouncilRegNumber,
            medicalCouncilRgDate,
        }, customConfig);
    } else {
        return axios.put(USER_API + "student/academics/" + id, {
            admissionnumber,
            physicsObtainedMarks,
            chemistryObtainedMarks,
            biologyObtainedMarks,
            vocationalObtainedMarks,
            totalMaxMarks,

            intermediateObtainedMarks,
            intermediateMaxMarks,
            intermediateGroupObtainedMarks,
            intermediateGroupMaxMarks,
            englishObtainedMarks,
            englishMaxMarks,

            collegeAddress,
            neetHallTicketNumber,
            neetRank,
            neetStateRank,
            neetObtainedMarks,
            neetMaxMarks,

            minQualifyingMarks,
            entranceObtainedMarks,
            selected,
            mbbsJoiningDate,
            mbbsPassingDate,
            universityAndState,

            mbbsCollegeAddress,
            firstMbbsMaxMarks,
            firstMbbsObtainedMarks,
            secondMbbsMaxMarks,
            secondMbbsObtainedMarks,
            finalMbbsPart1ObtainedMarks,
            finalMbbsPart1MaxMarks,
            finalMbbsPart2ObtainedMarks,
            finalMbbsPart2MaxMarks,

            pgNeetHallTicketNumber,
            pgNeetRank,
            pgNeetStateRank,
            pgNeetObtainedMarks,
            pgNeetMaxMarks,
            internshipStartDate,
            internshipEndDate,
            internshipDuration,
            internshipCollegeName,
            internshipCollegeAddress,

            internshipHospitalName,
            internshipHospitalAddress,
            medicalCouncilName,
            medicalCouncilRegNumber,
            medicalCouncilRgDate,
        }, customConfig);
    }
}

const acadamicInfo = (admission, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.get(USER_API + "student/academics/" + admission, customConfig);
}

const students = (token, course, yearofstudy, name, email, admissionnumber, mobileNumber, status, pending='', pendingOn='', searchBatch, current, size) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var filter = '';
    if(searchBatch) {
        filter += `batchYear=${searchBatch}&`
    }
    if (course) {
        filter += `course=${course}&`
    }
    if (yearofstudy) {
        filter += `yearofstudy=${yearofstudy}&`
    }
    if (name) {
        filter += `name=${name}&`
    }
    if (email) {
        filter += `email=${email}&`
    }
    if (admissionnumber) {
        filter += `admissionnumber=${admissionnumber}&`
    }
    if (mobileNumber) {
        filter += `contact=${mobileNumber}&`
    }
    if (status) {
        filter += `status=${status}&`
    }
    if(pending) {
        filter += `pending=${pending}&`
    }
    if(pendingOn) {
        filter += `pending-on=${pendingOn}&`
    }
    var params = `?${filter}page=${current}&size=${size}`;
    // var params = `?page=${current}&size=${size}`;
    if (filter !== '') {
        return axios.get(USER_API + "student/admissions/filter" + params, customConfig);
    }
    return axios.get(USER_API + "student/list" + params, customConfig);
}

const approvedStudents = (token, course, yearofstudy, name, email, admissionnumber, mobileNumber, status, current, size) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var filter = '';
    if (course) {
        filter += `course=${course}&`
    }
    if (yearofstudy) {
        filter += `yearofstudy=${yearofstudy}&`
    }
    if (name) {
        filter += `name=${name}&`
    }
    if (email) {
        filter += `email=${email}&`
    }
    if (admissionnumber) {
        filter += `admissionnumber=${admissionnumber}&`
    }
    if (mobileNumber) {
        filter += `contact=${mobileNumber}&`
    }
    if (status) {
        filter += `status=${status}&`
    }
    var params = `?${filter}page=${current}&size=${size}`;
    // var params = `?page=${current}&size=${size}`;
    if (filter !== '') {
        return axios.get(USER_API + "student/filter" + params, customConfig);
    }
    return axios.get(USER_API + "student" + params, customConfig);
}

const deleteStudent = (token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    return axios.delete(USER_API + "student/" + id, customConfig);
}

const admissions = (token, current, size, course, status, name, email, admissionNumber, mobileNumber) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = '';
    if (course) {
        filter += `course=${course}&`
    }
    if (status) {
        filter += `status=${status}&`
    }
    if (name) {
        filter += `name=${name}&`
    }
    if (email) {
        filter += `email=${email}&`
    }
    if (admissionNumber) {
        filter += `admissiondate=${admissionNumber}&`
    }
    if (mobileNumber) {
        filter += `contact=${mobileNumber}&`
    }
    var params = `?${filter}page=${current}&size=${size}`;
    if (filter !== '') {
        return axios.get(USER_API + "user/filter" + params, customConfig);
    }
    var newParams=params.replace("?","")
    return axios.get(USER_API + "user/admissions/list?statusnot=Approved&" + newParams, customConfig);
}

const admission = (admission, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    return axios.get(USER_API + "user/" + admission, customConfig);
}

const documents = (admission, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.get(DOC_API + "user/docs/student/" + admission, customConfig);
}

const deleteDoc = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(DOC_API + "user/docs/" + id, customConfig);
}

const updateDoc = (id,
    admissionnumber,
    documentstatus,
    token,
    remarks = '') => {
    const documentinfo = {
        admissionnumber,
        id,
        documentstatus,
        remarks,
    };
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.put(DOC_API + "user/docs", documentinfo, customConfig);
}

const dashboardAdminData = (token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    return axios.get(USER_API + "user/admissionscountbycourse", customConfig);
}

const updateRemarks = (id, admissionnumber, rollnumber, course, remarks, approval, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    return axios.put(USER_API + "student/remarks/" + id, {
        admissionnumber,
        rollnumber,
        course,
        remarks,
        approval,
    }, customConfig);
}

const updateStatus = (status, id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }
    return axios.put(USER_API + `student/${id}/${status}`, customConfig);
}

const uploadDoc = (
    id,
    file,
    documentname,
    admissionnumber,
    documenttype,
    documentsubtype,
    token,
    isUpdate,
) => {
    var documentstatus = 'Pending';
    var remarks = 'none';
    var documentdescription = 'none';
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    const documentinfo = {
        admissionnumber,
        documentname,
        documenttype,
        documentsubtype,
        documentstatus,
        remarks,
        documentdescription
    };
    formData.append('file', file, file.name);
    formData.append('documentinfo', JSON.stringify(documentinfo));
    if (isUpdate) {
        return axios.put(DOC_API + 'user/docs/' + id, formData, customConfig);
    }
    return axios.post(DOC_API + 'user/docs', formData, customConfig);
}

const getCourse = (token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var params = `?page=${1}&size=${50}`;
    return axios.get(USER_API + "course" + params, customConfig);
}

const setCourse = (data, token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (id) return axios.put(USER_API + `course/${id}`, data, customConfig);
    return axios.post(USER_API + "course", data, customConfig);
}

const getSubCourse = (courseName, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var params = `?courseid=${courseName}&page=${1}&size=${50}`;
    return axios.get(USER_API + "course/subcourse" + params, customConfig);
}

const setSubCourse = (data, token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (id) return axios.put(USER_API + `course/subcourse/${id}`, data, customConfig);
    return axios.post(USER_API + "course/subcourse", data, customConfig);
}

const getCategory = (courseName, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var params = `?courseid=${courseName}&page=${1}&size=${50}`;
    return axios.get(USER_API + "course/category" + params, customConfig);
}

const setCategory = (data, token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (id) return axios.put(USER_API + `course/category/${id}`, data, customConfig);
    return axios.post(USER_API + "course/category", data, customConfig);
}

const getDocType = (courseName, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var params = `?courseid=${courseName}&page=${1}&size=${50}`;
    return axios.get(USER_API + "course/doctype" + params, customConfig);
}

const setDocType = (data, token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (id) return axios.put(USER_API + `course/doctype/${id}`, data, customConfig);
    return axios.post(USER_API + "course/doctype", data, customConfig);
}

const getSubject = (courseName, subcourse, yearofstudy, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    var params = `?courseid=${courseName}&subcourseid=${subcourse}&yearofstudy=${yearofstudy}&page=${1}&size=${100}`;
    return axios.get(USER_API + "course/subject" + params, customConfig);
}

const setSubject = (data, token, id) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (id) return axios.put(USER_API + `course/subject/${id}`, data, customConfig);
    return axios.post(USER_API + "course/subject", data, customConfig);
}

const deleteCourse = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(USER_API + `course/${id}`, customConfig);
}

const deleteSubCourse = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(USER_API + `course/subcourse/${id}`, customConfig);
}

const deleteCategory = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(USER_API + `course/category/${id}`, customConfig);
}

const deleteDoctype = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(USER_API + `course/doctype/${id}`, customConfig);
}

const deleteSubject = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(USER_API + `course/subject/${id}`, customConfig);
}

const addMaterial = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    const materialinfo = {
        documentname: data.documentname,
        documenttype: data.documenttype,
        documentsubtype: data.documentsubtype,
        documentstatus: data.documentstatus,
        remarks: data.remarks,
        documentdescription: data.documentdescription,
        course: data.course,
        subcourse: data.subcourse,
        yearofstudy: data.yearofstudy,
        subject: data.subject,
    };
    formData.append('file', data.file, data.file.name);
    formData.append('coverpage', data.coverpage, data.coverpage.name)
    formData.append('materialinfo', JSON.stringify(materialinfo));
    if (data.id) {
        return axios.put(DOC_API + 'material/' + data.id, formData, customConfig);
    }
    return axios.post(DOC_API + 'material', formData, customConfig);
}

const deleteMaterial = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(DOC_API + "material/" + id, customConfig);
}

const getMaterials = (id, page, size, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (!id) return axios.get(DOC_API + `material?page=${page}&size=${size}`, customConfig);

    return axios.get(DOC_API + `material/${id}?page=${page}&size=${size}`, customConfig);
}

const getMarks = (search, page, size, token, admissionNumber) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = '';
    if (search.course) {
        filter += `course=${search.course}&`
    }
    if (search.yearOfStudy) {
        filter += `yearofstudy=${search.yearOfStudy}&`;
    }
    if (search.subcourse) {
        filter += `subcourse=${search.subcourse}&`
    }
    if (search.admissionnumber) {
        filter += `admissionnumber=${search.admissionnumber}&`
    }
    if (search.rollnumber) {
        filter += `rollnumber=${search.rollnumber}&`;
    }
    if (search.name) {
        filter += `name=${search.name}&`;
    }
    if (search.type) {
        filter += `type=${search.type}&`;
    }
    let params = `?${filter}page=${page}&size=${size}`;
    if (!admissionNumber) return axios.get(USER_API + `marks${params}`, customConfig);


    return axios.get(USER_API + `marks/${admissionNumber}`, customConfig);
}

const addSyllabus = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    const materialinfo = {
        documentname: data.documentname,
        documenttype: data.documenttype,
        documentsubtype: data.documentsubtype,
        documentstatus: data.documentstatus,
        remarks: data.remarks,
        documentdescription: data.documentdescription,
        course: data.course,
        subcourse: data.subcourse,
        yearofstudy: data.yearofstudy,
    };
    formData.append('file', data.file, data.file.name);
    formData.append('coverpage', data.coverpage, data.coverpage.name);
    formData.append('materialinfo', JSON.stringify(materialinfo));
    if (data.id) {
        return axios.put(DOC_API + 'syllabus/' + data.id, formData, customConfig);
    }
    return axios.post(DOC_API + 'syllabus', formData, customConfig);
}

const deleteSyllabus = (id, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.delete(DOC_API + "syllabus/" + id, customConfig);
}

const getSyllabus = (id, page, size, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    if (!id) return axios.get(DOC_API + `syllabus?page=${page}&size=${size}`, customConfig);

    return axios.get(DOC_API + `syllabus/${id}?page=${page}&size=${size}`, customConfig);
}

const getAllSlide = (pending, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = ''
    if(pending) {
        filter += `?pending=${pending}`
    }

    return axios.get(USER_API + `student/getAllSlides${filter}`, customConfig);
}

const slideStudent = (id, data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    // if (id) return axios.put(USER_API + `student/slide/${id}`, data, customConfig);
    if (id) return axios.put(USER_API + `student/slide`, data, customConfig);
}

const slideReview = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    return axios.put(USER_API + `student/submitReviewSlide`, data, customConfig);
}

const getAwards = (search, page, size, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = '';
    if (search.title) {
        filter += `title=${search.title}&`
    }
    if (search.description) {
        filter += `description=${search.description}&`
    }
    let params = `?${filter}page=${page}&size=${size}`;

    return axios.get(USER_API + `awards/${params}`, customConfig);
}

const getAttendance = (course,subcourse, yearofstudy, from, to, rollnumber, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = '';
    if (course) {
        filter += `course=${course}`;
    }
    if(subcourse){
        filter += `&subcourse=${subcourse}`;
    }
    if (yearofstudy) {
        filter += `&yearofstudy=${yearofstudy}`;
    }
    if (from) {
        filter += `&from=${from}`
    }
    if (to) {
        filter += `&to=${to}`
    }
    if (rollnumber) {
        filter += `&rollnumber=${rollnumber}`;
    }
    return axios.get(USER_API + `attendance?${filter}`, customConfig);
}

const uploadAttendance = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    formData.append('file', data.file, data.file.name);
    formData.append('course', data.course);
    formData.append('subcourse', data.subcourse);
    formData.append('yearofstudy', data.yearofstudy);

    return axios.post(USER_API + 'attendance/import', formData, customConfig);
}

const uploadMarks = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    formData.append('file', data.file, data.file.name);
    formData.append('course', data.course);
    formData.append('subcourse', data.subcourse);
    formData.append('yearofstudy', data.yearOfStudy);
    formData.append('type', data.type);

    return axios.post(USER_API + 'marks/import', formData, customConfig);
}

const importAdmission = (data, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
        }
    };
    const formData = new FormData();
    formData.append('file', data.file, data.file.name);

    let filter = '';
    if (data.course) {
        filter += `course=${data.course}`
    }
    if (data.category) {
        filter += `&category=${data.category}`
    }

    if (data.yearofstudy) {
        filter += `&yearofstudy=${data.yearofstudy}`
    }
    if (data.subcourse) {
        filter += `&subcourse=${data.subcourse}`
    }

    return axios.post(USER_API + `user/import?${filter}`, formData, customConfig);
}

const generateRollnumbers = (course, subcourse, yearofstudy, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    let filter = '';
    if (course) {
        filter += `course=${course}`
    }
    if (subcourse) {
        filter += `&subcourse=${subcourse}`
    }
    if (yearofstudy) {
        filter += `&yearofstudy=${yearofstudy}`
    }

    return axios.post(USER_API + `student/assign/rollnumber?${filter}`, {}, customConfig);
}

const rollnumberStatus = (course, yearofstudy, subcourse, token) => {
    const customConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };
    let filter = '';
    if (course) {
        filter += `course=${course}`
    }
    if (subcourse) {
        filter += `&subcourse=${subcourse}`
    }
    if (yearofstudy) {
        filter += `&yearofstudy=${yearofstudy}`
    }

    return axios.get(USER_API + `student/rollnumber/status?${filter}`, customConfig);
}

const DataService = {
    updateBasicInfo,
    updateStudentAdditionalInfo,
    studentByAdmission,
    studentReSubmit,
    updateAcadInfo,
    acadamicInfo,
    uploadDoc,
    students,
    approvedStudents,
    deleteStudent,
    admissions,
    admission,
    importAdmission,
    documents,
    deleteDoc,
    updateDoc,
    updateRemarks,
    dashboardAdminData,
    getCourse,
    getSubCourse,
    getCategory,
    getDocType,
    getSubject,
    setCourse,
    setSubCourse,
    setDocType,
    setCategory,
    setSubject,
    deleteCourse,
    deleteSubCourse,
    deleteCategory,
    deleteDoctype,
    deleteSubject,
    updateStatus,

    addMaterial,
    deleteMaterial,
    getMaterials,
    getMarks,

    addSyllabus,
    deleteSyllabus,
    getSyllabus,

    getAwards,
    getAttendance,
    uploadAttendance,

    uploadMarks,
    slideStudent,
    generateRollnumbers,
    rollnumberStatus,
    getAllSlide,
    slideReview,
};

export default DataService;