import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import {
    notificationMenuAction,
    languageMenuAction,
    authMenuAction,
    pagesMenuAction, mailMenuAction, profileMenuAction, openLeftSidebarAction
} from '../../actions/settingsAction';
import { setNotificationCount } from '../../actions/dataFieldAction';
import asramLogo from '../../assets/images/text_logo.png';
import { isStudent } from '../regex';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: AuthService.currentUser(),
            notificationData: [],
        };
    }

    // async componentDidMount() {

    // }
    getNotification = async (isRead = false) => {
        const { notificationMenu, notificationMenuAction, setNotificationCount } = this.props;
        if (notificationMenu && !isRead) {
            notificationMenuAction(!notificationMenu);
            return;
        }
        const user = AuthService.currentUser();
        try {
            let notificationCount = (await AuthService.getUnreadNotificationCount(user.token)).data.totalElements || 0;
            setNotificationCount(notificationCount);
        } catch (error) {
            console.log(error);
        }
        try {
            let res = (await AuthService.getUserNotification(user.token)).data.result;
            res = res.sort((a, b) => new Date(b.lastmodifieddate) - new Date(a.lastmodifieddate))
            if (res.length > 5) {
                res = [res[0], res[1], res[2], res[3], res[4], res[5]];
            }
            this.setState({
                notificationData: res || [],
            });
            notificationMenuAction(!notificationMenu);
        } catch (error) {
            console.log(error);
        }
    }
    UNSAFE_componentWillMount() {
        // notificationMenuAction(false)
        document.addEventListener("mousedown", this.hideLeftSidebarProxy, false);
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.hideLeftSidebarProxy, false);
    }
    hideLeftSidebarProxy = e => {
        const {
            // authMenuAction, pagesMenuAction, languageMenuAction, mailMenuAction,
            notificationMenuAction, profileMenuAction,
            // openLeftSidebarAction,
            notificationMenu, profileMenu,
            // toggleLeftBar

        } = this.props;

        let profile = document.querySelector('#__profile_menu__');
        let notification = document.querySelector('#__notification_menu__');

        if (notificationMenu && !notification.contains(e.target)) {
            notificationMenuAction(false);
        }

        if (profileMenu && !profile.contains(e.target)) {
            profileMenuAction(false);
        }

        // if(toggleLeftBar) {
        //     openLeftSidebarAction(false);
        // }

        // if (this.leftSidebar.contains(e.target)) {
        //     authMenuAction(false)
        //     pagesMenuAction(false)
        //     languageMenuAction(false)
        //     mailMenuAction(false)
        //     notificationMenuAction(false)
        //     return;
        // }
    };
    logout = () => {
        AuthService.logout(AuthService.currentUser().id);
    }

    handleNotificationRead = async () => {
        try {
            const currentUser = AuthService.currentUser();
            await AuthService.notificationRead(0, currentUser.token);
            await this.getNotification(true);
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        const { fixNavbar, darkHeader,
            notificationMenu, notificationMenuAction,
            profileMenu,
            profileMenuAction, notificationCount,
        } = this.props;
        const { notificationData } = this.state;
        const user = AuthService.currentUser();
        return (
            <div
                ref={leftSidebar => {
                    this.leftSidebar = leftSidebar;
                }}
                className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
                id="page_top">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="left">
                            {/* <div className="input-group">
                                <input type="text" className="form-control" placeholder="What you want to find" />
                                <div className="input-group-append">
                                    <button className={`btn btn-outline-secondary${boxShadow ? ' box_shadow' : ''}`} type="button">Search</button>
                                </div>
                            </div> */}
                            <img src={asramLogo} height={20} alt='Logo' />
                        </div>
                        <div className="right">
                            <ul className="nav nav-pills">
                                {/* <li className="nav-item dropdown" onBlur={() => this.setState({ pagesMenu: false })}> */}
                                {/* <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" onClick={() => pagesMenuAction(!pagesMenu)}>Pages</span>
                                    <div className={`dropdown-menu${pagesMenu ? ' show' : ''}`}>
                                        <Link className="dropdown-item" to="/pageEmpty">Empty page</Link>
                                        <Link className="dropdown-item" to="/pageProfile">Profile</Link>
                                        <Link className="dropdown-item" to="/pageSearch">Search Results</Link>
                                        <Link className="dropdown-item" to="/pageTimeline">Timeline</Link>
                                        <Link className="dropdown-item" to="/pageInvoices">Invoices</Link>
                                        <Link className="dropdown-item" to="/pagePricing">Pricing</Link>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item dropdown" onBlur={() => this.setState({ authMenu: false })}> */}
                                {/* <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" onClick={() => authMenuAction(!authMenu)} >Auth</span>
                                    <div className={`dropdown-menu${authMenu ? ' show' : ''}`}>
                                        <Link className="dropdown-item" to="/login">Login</Link>
                                        <Link className="dropdown-item" to="/signup">Register</Link>
                                        <Link className="dropdown-item" to="/forgotpassword">Forgot password</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/notfound">404 error</Link>
                                        <Link className="dropdown-item" to="/internalserver">500 error</Link>
                                    </div>
                                </li> */}
                            </ul>
                            <div className="notification d-flex header-role-notification-dropdown-wrapper">
                                {/* <div className={`dropdown d-flex${languageMenu ? ' show' : ''}`}>
                                    <span className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" onClick={() => languageMenuAction(!languageMenu)}><i className="fa fa-language"></i></span>
                                    <div className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow${languageMenu ? ' show dLang' : ''}`}>
                                        <span className="dropdown-item" >
                                            <img className="w20 mr-2" src="../assets/images/flags/us.svg" alt="avatar" />English
											</span>
                                        <div className="dropdown-divider"></div>
                                        <span className="dropdown-item" >
                                            <img className="w20 mr-2" src="../assets/images/flags/es.svg" alt="avatar" />Spanish
											</span>
                                        <span className="dropdown-item" >
                                            <img className="w20 mr-2" src="../assets/images/flags/jp.svg" alt="avatar" />japanese
											</span>
                                        <span className="dropdown-item" >
                                            <img className="w20 mr-2" src="../assets/images/flags/bl.svg" alt="avatar" />France
											</span>
                                    </div>
                                </div> */}
                                {/* <div className={`dropdown d-flex${mailMenu ? ' show' : ''}`}>
                                    <span className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" onClick={() => mailMenuAction(!mailMenu)}><i className="fa fa-envelope"></i>
                                        <span className="badge badge-success nav-unread"></span>
                                    </span>
                                    <div className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow${mailMenu ? ' show dMail' : ''}`}>
                                        <ul className="right_chat list-unstyled w350 p-0">
                                            <li className="online">
                                                <a href="/" className="media">
                                                    <img className="media-object" src="../assets/images/xs/avatar4.jpg" alt="avatar" />
                                                    <div className="media-body">
                                                        <span className="name">Donald Gardner</span>
                                                        <div className="message">It is a long established fact that a reader
                                                        </div>
                                                        <small>11 mins ago</small>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="online">
                                                <a href="/" className="media">
                                                    <img className="media-object " src="../assets/images/xs/avatar5.jpg" alt="avatar" />
                                                    <div className="media-body">
                                                        <span className="name">Wendy Keen</span>
                                                        <div className="message">There are many variations of passages of
                                                            Lorem Ipsum</div>
                                                        <small>18 mins ago</small>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="offline">
                                                <a href="/" className="media">
                                                    <img className="media-object " src="../assets/images/xs/avatar2.jpg" alt="avatar" />
                                                    <div className="media-body">
                                                        <span className="name">Matt Rosales</span>
                                                        <div className="message">Contrary to popular belief, Lorem Ipsum is
                                                            not simply</div>
                                                        <small>27 mins ago</small>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="online">
                                                <a href="/" className="media">
                                                    <img className="media-object " src="../assets/images/xs/avatar3.jpg" alt="avatar" />
                                                    <div className="media-body">
                                                        <span className="name">Phillip Smith</span>
                                                        <div className="message">It has roots in a piece of classical Latin
                                                            literature from 45 BC</div>
                                                        <small>33 mins ago</small>
                                                        <span className="badge badge-outline status"></span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="dropdown-divider"></div>
                                        <a href="/"
                                            className="dropdown-item text-center text-muted-dark readall">Mark all as
                                            read</a>
                                    </div>
                                </div> */}

                                {(isStudent.test(user?.role)) &&
                                    <div className='header-user-role'>
                                        {user?.role}
                                    </div>
                                }
                                {(isStudent.test(user?.role)) &&
                                    <div className='header-user-role'>
                                        {user?.userid}
                                    </div>
                                }

                                <div className={`dropdown d-flex`} id='__notification_menu__'>
                                    <button type='button' id="notificationIcon" className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1" onClick={this.getNotification}>
                                        {notificationCount > 0 && <span className="badge badge-danger nav-unread" style={{ top: '-5px' }}>{notificationCount}</span>}
                                        <i className="fa fa-bell-o"></i>
                                    </button>
                                    <div className={`dropdown-menu ${notificationMenu ? ' show dNoti' : ''}`} aria-labelledby="notificationIcon">
                                        <ul className="list-unstyled feeds_widget" >
                                            {notificationData.map((data, index) => {
                                                return (
                                                    <li key={index} className='dropdown-item' onClick={() => notificationMenuAction(!notificationMenu)} style={{ backgroundColor: `${data.isread ? '#f0f2f5' : ''}` }}>
                                                        <Link to={{
                                                            pathname: "/notificationsList",
                                                            state: { value: data }
                                                        }} style={{ display: 'flex', width: '100%', whiteSpace: 'normal' }} >
                                                            <div className="feeds-left">
                                                                <span className="avatar avatar-blue"><i className="fa fa-check"></i></span>
                                                            </div>
                                                            <div className="feeds-body ml-3">
                                                                <p className="text-muted mb-0">{data?.title}</p>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item text-center d-block text-muted-dark readall" style={{ width: '100%' }} onClick={this.handleNotificationRead}>Mark all as read</button>
                                    </div>
                                </div>
                                <div style={{ width: '170px' }} className={`dropdown d-flex${profileMenu ? ' show' : ''}`} id='__profile_menu__'>
                                    <span className="chip ml-3 profile-menu-anchor" onClick={() => profileMenuAction(!profileMenu)}>
                                        <span className="avatar"
                                            style={{ backgroundImage: "url(../assets/images/xs/avatar5.jpg)" }}></span>
                                        <span className="profile-menu-name">{this.state.user?.firstname} {this.state.user?.lastname} </span> <i className="fa fa-chevron-down"></i></span>
                                    <div className={`dropdown-menu dropdown-menu-right dropdown-menu-arrow${profileMenu ? ' show Profile' : ''}`}>
                                        {/* <Link className="dropdown-item" to="/pageprofile"><i
                                            className="dropdown-icon fe fe-user"></i> page profile</Link> */}
                                        <Link className="dropdown-item" to="/changepassword"><i
                                            className="dropdown-icon fe fe-user"></i>Change Password</Link>
                                        {/* <Link className="dropdown-item" to="/setting"><i
                                            className="dropdown-icon fe fe-settings"></i> Settings</Link> */}
                                        {/* <Link className="dropdown-item" to="/email"><span className="float-right"><span
                                            className="badge badge-primary">6</span></span><i
                                                className="dropdown-icon fe fe-mail"></i> Inbox</Link>
                                        <span className="dropdown-item" ><i
                                            className="dropdown-icon fe fe-send"></i> Message</span> */}
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/needHelp"><i
                                            className="dropdown-icon fe fe-help-circle"></i>Need Help?</Link>
                                        <Link className="dropdown-item" to="/login" onClick={this.logout}><i
                                            className="dropdown-icon fe fe-log-out"></i> Sign out</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = state => ({
    boxShadow: state.settings.isBoxShadow,
    profileMenu: state.settings.isProfileMenu,
    pagesMenu: state.settings.isPagesMenu,
    mailMenu: state.settings.isMailMenu,
    languageMenu: state.settings.isLanguageMenu,
    authMenu: state.settings.isAuthMenu,
    fixNavbar: state.settings.isFixNavbar,
    darkHeader: state.settings.isDarkHeader,
    notificationMenu: state.settings.isNotificationMenu,

    notificationCount: state.dataFields.notificationCount,
    toggleLeftBar: state.settings.isToggleLeftBar,
})

const mapDispatchToProps = dispatch => ({
    authMenuAction: (e) => dispatch(authMenuAction(e)),
    notificationMenuAction: (e) => dispatch(notificationMenuAction(e)),
    languageMenuAction: (e) => dispatch(languageMenuAction(e)),
    mailMenuAction: (e) => dispatch(mailMenuAction(e)),
    profileMenuAction: (e) => dispatch(profileMenuAction(e)),
    pagesMenuAction: (e) => dispatch(pagesMenuAction(e)),
    openLeftSidebarAction: (e) => dispatch(openLeftSidebarAction(e)),

    setNotificationCount: (e) => dispatch(setNotificationCount(e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Header);