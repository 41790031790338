import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
// import { TabContent, TabPane, Nav, NavLink as TabLink, NavItem } from "reactstrap";
import {
	Switch,
	Route,
	NavLink,
} from "react-router-dom";

import DefaultLink from "./DefaultLink";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import Routes from "../Route";
import AuthService from "../../services/AuthService";
import './menu.css';
import '../styles.css';

import {
	openLeftSidebarAction,
	openRightSidebarAction,
	openSettingbarAction,
	changeThemeColorAction,
	fontAction,
	darkModeAction,
	fixNavbarAction,
	darkHeaderAction,
	iconColorAction,
	darkSidebarAction,
	gradientColorAction,
	boxShadowAction,
	boxLayoutAction,
	darkMinSidebarAction,
	rtlAction,
	menuGridAction,
} from "../../actions/settingsAction";
import {
	// universityAdminMenu,
	universityStudentMenu,
	// adminMenu,
	universalMenu,
} from "./metisMenu";
// import classnames from 'classnames';
import asramLogo from "../../assets/images/asramlogo.png";
import {
	commonRoutes,
	disableRoutesToStudent,
	isPrincipal,
	isStudent,
	isSuperAdmin,
} from "../regex";
import {
	setCategory,
	setCourseData,
	setCourseDataById,
	setNotificationCount,
	setPermissions,
	setSubCourseData,
	setSubCourseDataById,
	setYearsData,
} from "../../actions/dataFieldAction";
import DataService from "../../services/DataService";
import { universalMenuV2, universalStudentMenuV2 } from "./metisMenuV2";
import { withRouter } from "react-router-dom";
import loader from "../../assets/images/loader.gif";

import { ReactComponent as DashboardIcon } from "../../assets/images/menu/dashboard.svg";
import { ReactComponent as AdmissionsIcon } from "../../assets/images/menu/admissions.svg";
import { ReactComponent as StudentsIcon } from "../../assets/images/menu/students.svg";
import { ReactComponent as NoticeBoardIcon } from "../../assets/images/menu/noticeboard.svg";
import { ReactComponent as PaymentsIcon } from "../../assets/images/menu/payments.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/menu/settings.svg";
import { ReactComponent as Student360Icon } from "../../assets/images/menu/student_360.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/menu/users.svg";
import { ReactComponent as LibraryIcon } from "../../assets/images/menu/books_white.svg";

const icons = {
	Dashboard: <DashboardIcon />,
	Admissions: <AdmissionsIcon />,
	Academics: <StudentsIcon />,
	Accounts: <PaymentsIcon />,
	Masters: <UsersIcon />,
	Communications: <NoticeBoardIcon />,
	Settings: <SettingsIcon />,
	Library: <LibraryIcon />,
	'Student 360': <Student360Icon />,
	'Payments': <PaymentsIcon />,
	Grievance: <LibraryIcon />
}

class Menu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuType: false,
			toggleThemeBar: false,
			openChatBar: false,
			user: AuthService.currentUser(),
			roleData: {},
			routesData: [],
			paymentDue: false,
			mqAmount: null,
			mainMenu: [],
			copyMenu: [],
			currentWidth: window.innerWidth,
		};
	}

	updateScreenSize = () => {
		this.setState({
			currentWidth: window.innerWidth
		})
	}
	async componentDidMount() {
		window.addEventListener('resize', this.updateScreenSize);
		let user = AuthService.currentUser();
		const { yearNames } = this.props;
		let paymentDue = false;
		let mqAmount = null;

		if (AuthService.isUserLoggedIn()) {
			try {
				let courseData = (await DataService.getCourse(user.token)).data.result;
				let courseDataById = {};
				for (let i of courseData) {
					courseDataById[i.id] = i;
				}
				this.props.setCourseDataById(courseDataById);
				this.props.setCourseData(courseData);
				await this.setSubCourse(courseData);
			} catch (error) {
				console.log(error);
			}

			let yearsData = user.yearofstudy
				.split(",")
				.sort()
				.reduce((acc, curr) => {
					acc.push({ label: yearNames[+curr - 1], value: curr });
					return acc;
				}, []);
			this.props.setYearsData(yearsData);

			try {
				const notificationCount =
					(await AuthService.getUnreadNotificationCount(user.token)).data
						.totalElements || 0;
				this.props.setNotificationCount(notificationCount);
			} catch (error) {
				console.log(error);
			}
		}

		try {
			if (isStudent.test(user.role)) {
				let categoryData = (await DataService.getCategory(user.course[0], user.token))?.data?.result || [];
				this.props.setCategory(categoryData);
				let result =
					(await AuthService.getPayment(user.userid, {}, 1, 150, user.token))
						?.data.result || [];
				const paymentDetails =
					result?.length > 1
						? result?.filter((el) => el?.mqAmount != null)
						: result?.filter(
							(item) => item?.status?.toLowerCase() !== "success"
						);
				mqAmount = paymentDetails?.[0]?.mqAmount;
				const dueDetails = (await AuthService.paymentDueExist(user.token))?.data;
				paymentDue = dueDetails["College Fee"] || +dueDetails['Pending'] !== 0 || false;
			}
		} catch (error) {
			console.log(error);
		}

		try {
			let k = [];
			let permissions = (await AuthService.getUserPermissions(user.token))
			if (permissions?.data?.result) {
				permissions = permissions.data.result
			} else {
				if (permissions?.status === 401) {
					AuthService.logout(AuthService.currentUser().id);
				}
			}
			k[user.role] = {};
			for (let j of permissions) {
				let {
					viewable = false,
					editable = false,
					addable = false,
					deletable = false,
					importable = false,
					exportable = false,
				} = j;
				if (viewable) {
					k[user.role][j.module] = {
						viewable,
						editable,
						addable,
						deletable,
						importable,
						exportable,
					};
				}
			}
			let routesData = Routes;
			if (!isSuperAdmin.test(user.role)) {
				routesData = Routes.filter((r) => {
					if (
						isStudent.test(user.role) &&
						paymentDue === false &&
						disableRoutesToStudent.test(r.name)
					) {
						return true;
					}
					return (
						(k[user.role][r.name] && k[user.role][r.name][r.type]) ||
						commonRoutes.test(r.name) ||
						(isPrincipal.test(user.role) && r.name === "AdvancedSearch") ||
						(isStudent.test(user.role) &&
							(r.name === "PaymentStatus" || r.name === "Transactions" || r.name === 'Academic' || r.name === 'Non Academic'))
					);
				});
			}

			for (let i of Object.keys(universalMenu)) {
				if (k[user.role][i]?.viewable) {
					let initialRoute = routesData.findIndex((data) => data.name === i);
					let actualRoute = routesData.findIndex((data) => data.path === "/");

					if (initialRoute !== -1 && actualRoute !== -1) {
						routesData[actualRoute] = {
							...routesData[actualRoute],
							name: i,
							pageTitle: i,
							component: routesData[initialRoute].component,
						};
						break;
					}
				}
			}

			this.props.setPermissions(k[user.role]);
			const m = isStudent.test(user.role) ? structuredClone(universalStudentMenuV2) : structuredClone(universalMenuV2)
			const mainMenu = isSuperAdmin.test(user.role) ? m : this.getMainMenu(m, k[user.role], paymentDue, mqAmount);

			this.activeMenu(mainMenu);
			await this.setState({
				user: AuthService.currentUser(),
				roleData: k[user.role],
				routesData,
				paymentDue,
				mqAmount,
				mainMenu,
				copyMenu: mainMenu,
			});

			if (isStudent.test(user.role) && paymentDue) {
				let course = (this.props.courseDataById[this.state.user.course[0]]?.name || '').toLowerCase();
				let category = ((this.props.categoryData.find((i) => i.id === this.state.user.category))?.name || '').toLowerCase();
				if (((course === 'mbbs' && category === 'c')
					|| (course === 'pg' && (category === 's2' || category === 's3')))
					&& mqAmount === null) {
					window.location.replace("/#/admissions");
					return;
				}

				window.location.replace("/#/payment-status");
			}
		} catch (error) {
			this.setState({
				user: AuthService.currentUser(),
			});
			if (error.response?.status === 401) {
				AuthService.logout(AuthService.currentUser().id);
			}

		}
	}

	componentWillUnmount() {
		this.setState({
			mainMenu: [],
			copyMenu: [],
			roleData: {}
		})
		window.removeEventListener('resize', this.updateScreenSize);
	}

	getMainMenu = (menu, permissions, paymentDue, mqAmount) => {
		const user = AuthService.currentUser();

		let course = (this.props.courseDataById[this.state.user.course[0]]?.name || '').toLowerCase();
		let category = ((this.props.categoryData.find((i) => i.id === this.state.user.category))?.name || '').toLowerCase();
		let updatedMenu = menu.reduce((result, m) => {
			if (m.content) {
				m.content = this.getMainMenu(m.content, permissions, paymentDue, mqAmount);
				if (m.content.length > 0) {
					result.push(m);
				}
			}
			if ((permissions?.[m.name] && permissions?.[m.name]?.[m.type]) || m?.allow) {

				if (isStudent.test(user.role)) {
					if (((course === 'mbbs' && category === 'c')
						|| (course === 'pg' && (category === 's2' || category === 's3')))
						&& mqAmount === null) {
						if (m.name === 'Admissions') {
							result.push(m);
						}

					} else {
						if (!(paymentDue && disableRoutesToStudent.test(m.name))) {
							result.push(m);
						}
					}
				} else {
					result.push(m);
				}
			}
			return result;
		}, []);
		return updatedMenu;
	}

	setSubCourse = async (courseData) => {
		let subCourseData = {};
		let subCourseDataById = {};
		const user = AuthService.currentUser();
		try {
			courseData.forEach(async (course) => {
				let data =
					(await DataService.getSubCourse(course.id, user.token))?.data
						?.result || [];
				subCourseData[course.name] = data;
				subCourseDataById[course.id] = data;
			});
		} catch (error) {
			console.log(error);
		}
		this.props.setSubCourseData(subCourseData);
		this.props.setSubCourseDataById(subCourseDataById);
	};

	closeBar = (e) => {
		e.stopPropagation();
		// let menu = JSON.parse((JSON.stringify(this.state.copyMenu)).replace(/"show":true/g, `"show":false`));
		// this.props.openRightSidebarAction(false)
		// this.setState({ toggleThemeBar: false })
		this.setState({
			mainMenu: this.state.copyMenu
		})
	};
	openThemeBarAction = () => {
		this.setState({ toggleThemeBar: !this.state.toggleThemeBar });
	};
	openChatBarAction = () => {
		this.setState({ openChatBar: !this.state.openChatBar });
	};
	toggleAction = () => {
		const { menuGrid, menuGridAction } = this.props;
		this.setState({ menuType: false });
		menuGridAction(!menuGrid);
	};

	getMenu = () => {
		let { roleData, paymentDue, mqAmount } = this.state;
		let menu = [];
		if (isStudent.test(this.state.user.role)) {
			Object.keys(universityStudentMenu).forEach((i) => {
				if (
					roleData[i]?.viewable &&
					!(paymentDue && disableRoutesToStudent.test(i))
				) {
					menu.push(universityStudentMenu[i]);
				}
			});

			let course = (this.props.courseDataById[this.state.user.course[0]]?.name || '').toLowerCase();
			let category = ((this.props.categoryData.find((i) => i.id === this.state.user.category))?.name || '').toLowerCase();
			if (((course === 'mbbs' && category === 'c')
				|| (course === 'pg' && (category === 's2' || category === 's3')))
				&& mqAmount !== null) {

				menu.push(universityStudentMenu.Transactions);
				menu.push(universalMenu.PaymentStatus);
			}

			if (category !== 's3' && category !== 's2' && category !== 'c') {
				menu.push(universityStudentMenu.Transactions);
				menu.push(universalMenu.PaymentStatus);
			}
			return menu;
		}

		Object.keys(universalMenu).forEach((i) => {
			if (universalMenu[i].content) {
				let a = structuredClone(universalMenu[i]);
				a.content = a.content.filter((c) => roleData[c.id]?.viewable);
				if (a.content.length > 0) {
					menu.push(a);
				}
			} else if (roleData[i]?.viewable) {
				menu.push(universalMenu[i]);
			}
		});

		return menu;
	};

	handleShowMenu = (e, location) => {
		e.stopPropagation();

		let menu = this.state.mainMenu;
		const targetElement = e.currentTarget.nextElementSibling;
		targetElement.style.display = 'block';
		targetElement.style.top = '0';
		const elementData = targetElement.getBoundingClientRect();
		if (Math.floor(elementData.bottom) > window.innerHeight) {
			targetElement.style.top = `${window.innerHeight - elementData.bottom}px`
		}
		targetElement.style.display = '';
		menu = JSON.parse((JSON.stringify(this.state.mainMenu)).replace(/"show":true/g, `"show":false`));
		let a = menu;
		for (let i of location) {
			a[i].show = !a[i].show;
			if (a[i].content) {
				a = a[i]?.content
			}
		}
		this.setState({
			mainMenu: menu
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.location?.pathname !== prevProps.location?.pathname) {
			const menu = JSON.parse((JSON.stringify(this.state.copyMenu)).replace(/"active":true/g, `"active":false`));
			this.activeMenu(menu);
			this.setState({
				mainMenu: menu,
				copyMenu: menu
			})
		}
	}

	activeMenu = (menu) => {
		for (const m of menu) {
			if (m.content) {
				m.active = this.checkMenu(m.content);
			}
		}
	}

	checkMenu = (menu) => {
		for (const m of menu) {
			if (m.content) {
				let result = this.checkMenu(m.content);
				m.active = result;
				if (result) return result;
			}
			if (m.to && m.to === this.props.location.pathname) {
				return true;
			}
		}
		return false;
	}

	handleNavClick = async (e, m) => {
		e.stopPropagation();
		if (m.label === 'OAuth') {
			try {
				const currentUser = AuthService.currentUser();
				let result = (await AuthService.googleAuth(currentUser.token)).data.url;
				window.location.href = result;
			} catch (error) {
				console.log(error);
			}
		}
	}

	getNestedMenu = (menu, location) => {
		return (
			menu?.map((m, key) => {
				if (m.content) {
					return (
						<div className="btn-group dropright" key={key}>
							<div type="button" className={`btn dropdown-toggle side_menu`} data-toggle="dropdown" onClick={(e) => this.handleShowMenu(e, [...location, key])} aria-expanded="false" style={{ color: 'white', width: '240px', background: `${m.active ? '#007bff' : ''}` }}>
								<div style={{ display: 'flex', gap: '10px' }}>
									{icons[m.label] && <span style={{ width: '20px', height: '20px' }}>{icons[m.label]}</span>}
									<span className="font-16">{m.label}</span>
								</div>
							</div>
							<div className={`dropdown-menu ${m.show ? 'show' : ''}`} style={{ background: '#181272', color: 'white' }}>
								{this.getNestedMenu(m.content, [...location, key])}
							</div>
						</div>
					)
				} else if (m.to === 'lms.asram.in') {
					return (
						<a className="dropdown-item" href='https://lms.asram.in' target="_blank" rel='noopener noreferrer' key={key} style={{ padding: '8px 20px', color: 'white', gap: '10px', display: 'flex' }}>
							{icons[m.label] && <span style={{ width: '20px', height: '20px' }}>{icons[m.label]}</span>}
							<span className="font-16">{m.label}</span>
						</a>
					)
				} else {
					return (
						<NavLink className='dropdown-item' to={m.to} key={key} pathname={m?.label} style={{ padding: '8px 20px', color: 'white', gap: '10px', display: 'flex' }} onClick={(e) => this.handleNavClick(e, m)} >
							{icons[m.label] && <span style={{ width: '20px', height: '20px' }}>{icons[m.label]}</span>}
							<span className="font-16">{m.label}</span>
						</NavLink>
					)
				}
			})
		)
	}
	render() {
		const {
			// openSettingbarAction, toggleSettingbar,
			// openLeftSidebarAction, toggleLeftBar, changeThemeColorAction, themeColor,
			// fontAction, darkModeAction, isDarkMode, fixNavbarAction, isFixNavbar,
			// darkHeaderAction, isDarkHeader, iconColorAction, isIconColor,
			// gradientColorAction, isGradientColor, darkSidebarAction, isDarkSidebar,
			// darkMinSidebarAction, isMinSidebar, boxShadowAction, isBoxShadow,
			// boxLayoutAction, isBoxLayout, rtlAction, isRtl,
			// menuGrid,
			isMinSidebar,
			toggleLeftBar,
			openLeftSidebarAction,
		} = this.props;
		const {
			menuType,
			mainMenu,
			paymentDue,
			user,
			// openChatBar, settingTab, rightTab,
			routesData,
		} = this.state;
		// const userMenu = isSuperAdmin.test(this.state.user.role)
		// 	? universityAdminMenu
		// 	: this.getMenu();
		// const userAdminMenu = isSuperAdmin.test(this.state.user.role)
		// 	? adminMenu
		// 	: null;

		return (
			<>
				<div id="header_top" className={`header_top ${isMinSidebar ? "dark" : ""} d-block d-md-none`}>
					<div className="container">
						<div className="hleft">
							<div className="dropdown">
								<span
									onClick={() => openLeftSidebarAction(!toggleLeftBar)}
									className="nav-link icon menu_toggle"
								>
									<i className="fe fe-align-center"></i>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div id="left-sidebar" className="sidebar pl-0" style={{ zIndex: 20 }} onClick={(e) => this.closeBar(e)}>
					<img className="d-block margin-auto" src={asramLogo} height={75} width={225} alt="Logo" style={{ background: 'white', padding: '10px', borderRadius: '10px' }} />
					<br />
					<ul className="nav nav-tabs">
						<li className="nav-item">
							<span
								className={`nav-link ${!menuType ? " active" : ""}`}
								style={{ fontWeight: "bolder" }}
								data-toggle="tab"
								onClick={() => this.setState({ menuType: false })}
							>
								University
							</span>
						</li>

					</ul>

					{this.state.currentWidth > 768 ?
						<div className="tab-content mt-3">
							<div className='' id="menu-uni" role="tabpanel" style={{ position: 'fixed', display: 'flex', flexDirection: 'column', minWidth: '240px' }}>
								{this.getNestedMenu(mainMenu, [])}
							</div>
						</div>
						:
						<div className="tab-content mt-3">
							<div className={`tab-pane fade${!menuType ? " show active" : ""}`} id="menu-uni" role="tabpanel">
								<MetisMenu
									className="sidebar-nav"
									content={mainMenu}
									noBuiltInClassNames={true}
									activeLinkFromLocation
									classNameItemHasVisibleChild="open"
									classNameContainer="metismenu"
									// classItemActive="active"
									classNameContainerVisible="hidden"
									classNameLink=""
									// iconNamePrefix="fa fa-"
									LinkComponent={(e) => (
										<DefaultLink
											itemProps={e}
											paymentDue={paymentDue}
											user={user}
										/>
									)}
								/>
							</div>
						</div>
					}
				</div>
				<div className="page" onClick={(e) => this.closeBar(e)}>
					<Header />
					<Suspense fallback={
						<div
							className="d-flex justify-content-center text-center align-items-center"
							style={{ width: "100%", height: "80vh" }}
						>
							<img src={loader} alt="loader" width="200" height="200" />
						</div>
					}>
						<Switch>
							{routesData.map((layout, i) => {
								return (
									<Route
										key={i}
										exact={layout.exact}
										path={layout.path}
										component={layout.component}
									></Route>
								);
							})}
						</Switch>
					</Suspense>
					{window.location.pathname !== "/changepassword" && <Footer />}
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	// darkMinSidebar: state.settings.isMinSidebar,
	toggleLeftBar: state.settings.isToggleLeftBar,
	toggleRightBar: state.settings.isToggleRightBar,
	toggleSettingbar: state.settings.isOpenSettingBar,
	themeColor: state.settings.themeColor,
	isDarkMode: state.settings.isDarkMode,
	isFixNavbar: state.settings.isFixNavbar,
	isDarkHeader: state.settings.isDarkHeader,
	isDarkSidebar: state.settings.isDarkSidebar,
	isGradientColor: state.settings.isGradientColor,
	isIconColor: state.settings.isIconColor,
	isBoxShadow: state.settings.isBoxShadow,
	isBoxLayout: state.settings.isBoxLayout,
	isMinSidebar: state.settings.isMinSidebar,
	menuGrid: state.settings.isMenuGrid,
	isRtl: state.settings.isRtl,

	yearNames: state.dataFields.yearNames,
	courseDataById: state.dataFields.courseDataById,
	categoryData: state.dataFields.categoryData,
});

const mapDispatchToProps = (dispatch) => ({
	openLeftSidebarAction: (e) => dispatch(openLeftSidebarAction(e)),
	openRightSidebarAction: (e) => dispatch(openRightSidebarAction(e)),
	openSettingbarAction: (e) => dispatch(openSettingbarAction(e)),
	changeThemeColorAction: (e) => dispatch(changeThemeColorAction(e)),
	fontAction: (e) => dispatch(fontAction(e)),
	darkModeAction: (e) => dispatch(darkModeAction(e)),
	fixNavbarAction: (e) => dispatch(fixNavbarAction(e)),
	darkHeaderAction: (e) => dispatch(darkHeaderAction(e)),
	iconColorAction: (e) => dispatch(iconColorAction(e)),
	darkSidebarAction: (e) => dispatch(darkSidebarAction(e)),
	darkMinSidebarAction: (e) => dispatch(darkMinSidebarAction(e)),
	boxShadowAction: (e) => dispatch(boxShadowAction(e)),
	boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
	gradientColorAction: (e) => dispatch(gradientColorAction(e)),
	menuGridAction: (e) => dispatch(menuGridAction(e)),
	rtlAction: (e) => dispatch(rtlAction(e)),

	setCourseData: (e) => dispatch(setCourseData(e)),
	setPermissions: (e) => dispatch(setPermissions(e)),
	setSubCourseData: (e) => dispatch(setSubCourseData(e)),

	setCourseDataById: (e) => dispatch(setCourseDataById(e)),
	setSubCourseDataById: (e) => dispatch(setSubCourseDataById(e)),
	setNotificationCount: (e) => dispatch(setNotificationCount(e)),
	setYearsData: (e) => dispatch(setYearsData(e)),
	setCategory: (e) => dispatch(setCategory(e)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
