const initialState = {
	courseData: [],
	subcourseData: {},
	categoryData: [],
	yearsData: [],
	yearNames: ['First Year', 'Second Year', 'Third Year', 'Fourth Year', 'Fifth Year'],
	permissionData: {},
	courseDataById: {},
	subCourseDataById: {},
	notificationCount: 0,
	noticeData: [],
	timeOut: false,
}

const data = (state = initialState, action) => {
	switch (action.type) {
		case 'NOTICEBOARD':
			return {
				...state,
				noticeData: action.payload
			}
		case 'COURSE':
			return {
				...state,
				courseData: action.payload
			}
		case 'SUBCOURSE':
			return {
				...state,
				subcourseData: action.payload
			}
		case 'CATEGORY':
			return {
				...state,
				categoryData: action.payload
			}
		case 'YEARS':
			return {
				...state,
				yearsData: action.payload
			}
		case 'PERMISSION':
			return {
				...state,
				permissionData: action.payload,
			}
		case 'COURSEID':
			return {
				...state,
				courseDataById: action.payload,
			}
		case 'SUBCOURSEID':
			return {
				...state,
				subCourseDataById: action.payload,
			}
		case 'NOTIFICATIONCOUNT':
			return {
				...state,
				notificationCount: action.payload,
			}
		case 'TIMEOUT':
			return {
				...state,
				timeOut: action.payload,
			}
		default:
			return state
	}
}

export default data;